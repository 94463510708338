import { styled } from "styled-components";
import { ActivityProps } from "../interfaces/WorkInterfaces";
import { Typography } from "./Typography";
import { Icon } from "./Icon";
import { colors, device } from "../styles/stylesConfig";
import { formatFullDateText } from "../utils/Utilities";

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 50px;
    border-bottom: 1px solid ${colors.grey_normal};
    padding: 0px 10px 25px 10px;

    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0px;
    }

`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: start;
    white-space: normal;
    text-overflow: ellipsis;

    & > h1 > span {
        font-weight: 600 !important;
    }
`

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    @media ${device.xs} {
        flex-direction: column;
        width: 100%;
    }
`

const Status = styled.div`
    border-radius: 4px;
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';
    margin-left: 15px;
    margin-right: 15px;

    & > h1 {
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        padding: 10px 15px;
    }

    @media ${device.sm} {
        margin-left: 10px;
        margin-right: 10px;

        & > h1 {
            font-weight: 600;
            font-size: 10px;
            padding: 7%.5 12.5px;
        }
    }

    @media ${device.xs} {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 75%;

        & > h1 {
            font-weight: 600;
            font-size: 10px;
            padding: 7%.5 12.5px;
        }
    }


`

const DateContainer = styled.div`
    position: absolute;
    left: 0px;
    top: -25px;
`

interface Props {
    data: ActivityProps;
}

export const ActivityItem = ({ data }: Props) => {

    const { id, creator, date, text, changeState } = data;

    const setStateIcon = () => {

        switch (changeState?.status) {
            case 'changed':
                return require('../images/arrow-right-primary-dark.png')
            case 'toApprove':
                return require('../images/pending.png')
            case 'approved':
                return require('../images/stateApproved.png')
        }

    }

    return (

        <ItemContainer key={id}>

            <InfoContainer>

                <Typography size="14px" style={{ width: 'fit-content', position: 'relative' }}>
                    <span>{creator}</span> {text}

                    <DateContainer>

                        <Typography size="12px" color={colors.grey}>
                            {formatFullDateText(date)}
                        </Typography>

                    </DateContainer>

                </Typography>

            </InfoContainer>

            {
                changeState && (
                    
                    <StatusContainer>

                        <Status
                            style={{ backgroundColor: `${changeState.stateFrom.color}`, marginLeft: 0 }}
                        >
                            <Typography size="14px">
                                {changeState.stateFrom.name}
                            </Typography>

                        </Status>

                        <Icon size="M" src={setStateIcon()} />

                        <Status
                            style={{ backgroundColor: `${changeState.stateTo.color}`, marginRight: 0 }}
                        >
                            <Typography size="14px">
                                {changeState.stateTo.name}
                            </Typography>

                        </Status>

                    </StatusContainer>
                )
            }

        </ItemContainer>

    )
}