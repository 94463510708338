import { styled } from 'styled-components';
import { colors, device } from '../styles/stylesConfig';
import { ReactNode, useEffect, useState } from 'react';
import { ProyectsMenu } from './ProyectsMenu';
import { ProyectHeaderBar } from './ProyectHeaderBar';
import { useLocation } from 'react-router-dom';

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100%;
    position: relative;
    background-color: ${colors.info_light};
    @media ${device.lg} {
        background-color: white;
    }
`

const ProyectDataContainer = styled.div`
    min-width: 100%;
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: end;
    padding-top: 70px;

    & > h1:first-child {
        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }
    @media ${device.sm} {
        align-items: start;
        text-align: center;
    }
`

const SectionScreenContainer = styled.div<{ height: string }>`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 79.5%;
    min-height: ${props => props.height};
    margin-left: 15px;
    border: 1px solid ${colors.grey_normal};
    border-radius: 10px 0px 0px 0px;
    background-color: white;

    @media ${device.lg} {
        width: 100%;
        min-height: auto;
        box-shadow: none;
        border: none;
        border-radius: 0px;
        margin-left: 0px;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    
    & > h1:first-child {
        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.lg} {
        padding-top: 100px;
    }

    @media ${device.sm} {
        padding-top: 100px;
        align-items: center;
        text-align: center;
    }
`

interface Props {
    children?: ReactNode
}

export const SecondaryScreensContainer = ({ children }: Props) => {

    const location = useLocation()

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const updateWindowHeight = () => {

        setWindowHeight(window.innerHeight);

    }

    useEffect(() => {

        window.addEventListener('resize', updateWindowHeight)

        return () => {
            window.removeEventListener('resize', updateWindowHeight)
        }

    }, [location])

    return (

        <MainContainer>

            <ProyectHeaderBar />

            <ProyectDataContainer>

                <ProyectsMenu />

                <SectionScreenContainer height={`${windowHeight - 70}px`}>

                    <ContentContainer>

                        {children}

                    </ContentContainer>

                </SectionScreenContainer>

            </ProyectDataContainer>

        </MainContainer>

    )
}
