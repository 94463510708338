import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { formatExpirationTime, getStateTitleDaysLeft } from "../utils/Utilities";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";
import { useDispatch } from "react-redux";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    position: relative;

    & > h1 {
        width: 100%;    
        text-align: start;
        margin-bottom: 10px;
        margin-left: 15px;

        @media ${device.sm} {
        text-align: center;
        margin-left: 0px;
    }
    }
`

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    border: 1px solid ${colors.grey_normal};
    padding: 15px;
    border-radius: 5px;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #ddd;
    
    @media ${device.sm} {
        width: 100vw;
        justify-content: center;
        border-left: none;
        border-right: none;
        border-radius: 0px;
    }
`

const ItemsContainer = styled.div`
    min-width: 100%;
    display: grid;
    gap: 5px;
    grid-template-columns: 70px repeat(3, minmax(300px, 1fr));
    padding: 2.5px 0px;

    &:last-of-type {
        padding-bottom: 0px;
    }
`

const BoxData = styled.button`
    width: 100%;
    height: 30px;
    background-color: ${colors.grey_light};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    border: none;
    cursor: pointer;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

interface Props {
    title: string;
    color: string;
    worksList: WorkProps[];
    setWorkDetailModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setWorkDetailSelected: React.Dispatch<React.SetStateAction<WorkProps>>;
}

export const WorksView = ({ title, color, worksList, setWorkDetailModalVisible, setWorkDetailSelected }: Props) => {

    const dispatch = useDispatch();

    const handleClick = async (value: WorkProps) => {

        setWorkDetailSelected(value);
        setWorkDetailModalVisible(true);

        const auth = getAuth();

        const tokenResult = await auth.currentUser!.getIdTokenResult(true)

        dispatch(login({
            uid: auth.currentUser!.uid,
            name: auth.currentUser!.displayName,
            token: tokenResult.token,
            expirationTime: formatExpirationTime(tokenResult.expirationTime)
        }))

    }

    return (

        <MainContainer>

            <Typography
                size='20px'
                bold
                color={color}
            >
                {title}
            </Typography>

            <GroupContainer>

                <ItemsContainer>

                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}></BoxData>
                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}><Typography size='14px' bold>Cliente</Typography></BoxData>
                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}><Typography size='14px' bold>Dirección</Typography></BoxData>
                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}><Typography size='14px' bold>Estado</Typography></BoxData>

                </ItemsContainer>

                {
                    worksList.map((value, index) => {

                        const { id, client, address, state } = value

                        return (

                            <ItemsContainer key={id}>

                                <BoxData style={{ backgroundColor: 'transparent' }}>
                                    <Button
                                        text="Abrir"
                                        type="terciary"
                                        icon={require('../images/open-primary.png')}
                                        style={{ fontSize: 14, fontWeight: "normal" }}
                                        onClick={() => handleClick(value)}
                                    />
                                </BoxData>
                                <BoxData onClick={() => handleClick(value)} style={{ borderLeft: `2px solid ${color}`, backgroundColor: index % 2 === 0 ? colors.border : undefined }}><Typography size='14px'>{client}</Typography></BoxData >
                                <BoxData onClick={() => handleClick(value)} style={{ backgroundColor: index % 2 === 0 ? colors.border : undefined }}><Typography size='14px'>{address}</Typography></BoxData >
                                <BoxData onClick={() => handleClick(value)} style={{ backgroundColor: state.color }}><Typography size='14px' bold color="white">{state.name === 'Para entregar' || state.name === 'Para retirar' ? getStateTitleDaysLeft(value) : state.name}</Typography></BoxData>

                            </ItemsContainer>
                        )
                    })
                }

            </GroupContainer>


        </MainContainer>

    )
}