import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { login } from '../actions/auth';
import { PublicRouter } from './PublicRouter';
import { PrivateRouter } from './PrivateRouter';
import { LoadingScreen } from '../components/LoadingScreen';
import Swal from 'sweetalert2';
import { removeError } from '../actions/ui';
import styled from 'styled-components';
import { formatExpirationTime } from '../utils/Utilities';
import axios from 'axios';
import { endpoints } from '../helpers/FirebaseHelper';
import { StoreProps } from '../interfaces/ReducerInterfaces';

const RouterContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: inline-block;
    height: 100vh;
`

export const AppRouter = () => {

    const dispatch = useDispatch();

    const { msgError, loading } = useSelector((state: any) => state.ui);

    const { name, expirationTime } = useSelector((state: StoreProps) => state.auth);

    const [checking, setChecking] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {

        const auth = getAuth();

        onAuthStateChanged(auth, async (user) => {
            if (user?.uid) {

                const tokenResult = await user.getIdTokenResult(true)

                dispatch(login({
                    uid: user.uid,
                    name: user.displayName,
                    token: tokenResult.token,
                    expirationTime: formatExpirationTime(tokenResult.expirationTime)
                }))

                setIsLoggedIn(true);

                if (user.emailVerified) {

                    if (!isVerified) {

                        setIsVerified(true);

                    }

                } else {

                    setIsVerified(false);

                }


            } else {

                setIsLoggedIn(false);

            }

            setChecking(false);

        })

    }, [isVerified, dispatch, setChecking, setIsLoggedIn, setIsVerified]);

    useEffect(() => {

        const setErrorModal = async () => {
            await Swal.fire({
                titleText: 'Oops...',
                html: msgError,
                icon: 'error',
            }).then(async () => {

                if (msgError.includes('se venció tu sesión')) {

                    console.error('Se venció tu sesión');

                    const ip = await fetch('https://api.ipify.org?format=json')
                        .then(response => response.json())
                        .then(data => {
                            return data.ip
                        })
                        .catch(error => {
                            console.error('Error fetching IP address:', error);
                        });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS 2.0',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg: 'Error al actualizar el token 2.0', expirationTime, ip }
                    })

                    window.location.reload()

                }

                dispatch(removeError())



            })
        }

        if (msgError !== null)
            setErrorModal();


    }, [dispatch, msgError])

    if (checking) {

        return <LoadingScreen />
    }

    return (

        <Router>

            <RouterContainer>

                {loading && <LoadingScreen />}

                <Switch>

                    {
                        (isLoggedIn)
                            ? (
                                <Route
                                    exact
                                    component={PrivateRouter}
                                />
                            )
                            : (
                                <Route
                                    exact
                                    component={PublicRouter}
                                />
                            )
                    }


                    <Redirect to='/auth/signIn' />

                </Switch>

            </RouterContainer>

        </Router>

    )
}
