
export const CalendarEventBox = ({ event }: any) => {

    const { title, notes } = event;

    return (
        <>

            <strong style={{ fontSize: 12 }}>{title}</strong>

            <span style={{ fontSize: 12 }}> - {JSON.parse(notes).address}</span>

        </>
    )
}
