import { styled } from 'styled-components';
import { device } from '../../../styles/stylesConfig';
import { useState } from 'react';
import { initialStateNewStock } from '../../../utils/Jsons';
import { ActionScreenHeader } from '../../../components/ActionScreenHeader';
import { StockListView } from '../../../components/StockListView';
import { NewStockModal } from '../../../components/NewStockModal';
import { StockProps } from '../../../interfaces/StockInterfaces';
import { useSelector } from 'react-redux';

const ScreenContainer = styled.div`
position: relative;
width: 100%;

    @media ${device.lg} {

        & > h1 {
        visibility: hidden;
        position: absolute;
        }
    }

    @media ${device.sm} {
        max-width: 80%;

    }
`

export const StockScreen = () => {

    const { stock: stockList } = useSelector((state: any) => state.srandamios);

    const [newProductModal, setNewProductModal] = useState<{ visible: boolean, data: StockProps }>({
        visible: false,
        data: initialStateNewStock
    });

    return (

        <ScreenContainer>

            <ActionScreenHeader
                title='Stock'
                primaryButtonText='Agregar producto'
                primaryButtonIcon={require('../../../images/plus-white.png')}
                primaryButtonAction={() => { setNewProductModal({ visible: true, data: initialStateNewStock }) }}
            />

            {
                newProductModal.visible && (
                    <NewStockModal
                        data={newProductModal.data}
                        stockList={stockList}
                        setVisible={setNewProductModal}
                    />
                )
            }

            <StockListView
                stockList={stockList}
                setModalVisible={setNewProductModal}
            />

        </ScreenContainer>


    )
}
