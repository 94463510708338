import { useHistory } from "react-router-dom";
import { Icon } from "./Icon";
import { styled } from "styled-components";
import { Typography } from "./Typography";
import { colors } from "../styles/stylesConfig";
import { useDispatch } from 'react-redux';
import { closeMenu, closeNotifications } from "../actions/ui";

const ItemButton = styled.button<{ issubitem: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${props => props.issubitem === 'true' ? '20px' : '50px'};
    width: 100%;
    padding: 15px;
    padding-left: 35px;
    border: none;
    background-color: transparent;
    border-top: transparent 1px solid;
    border-bottom: transparent 1px solid;

    &:hover {
        cursor: pointer;
        border-top: 1px solid ${colors.info};
        border-bottom: 1px solid ${colors.info};
        box-shadow: 0px 0px 6px #00000026;
    }
`

export interface MenuItemProps {
    itemName: string;
    icon: string;
    iconSelected: string;
    selected: boolean;
    goTo: string;
    isSubItem: boolean;
}

export const MenuItem = ({ itemName, icon, iconSelected, selected, isSubItem, goTo }: MenuItemProps) => {

    const history = useHistory();

    const dispatch = useDispatch();

    return (

        <ItemButton
            issubitem={isSubItem.toString()}
            onClick={() => {
                dispatch(closeMenu());
                dispatch(closeNotifications());
                history.push(goTo);
            }}
        >

            <Icon size="M" src={selected ? iconSelected : icon} />

            <Typography
                size="14px"
                bold={selected}
                color={selected ? colors.primary : colors.primary_dark}
                style={{ marginLeft: 15, textAlign: 'start' }}
            >
                {itemName}
            </Typography>

        </ItemButton>

    )
}
