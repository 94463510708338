import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useState } from "react";
import { Input } from "./Input";
import { formatPrice, getFullDate } from "../utils/Utilities";
import { showMixinToast, showModalMessage } from "../utils/Alerts";
import { PaymentMethodTypes } from "../interfaces/WorkInterfaces";
import { BudgetMovementProps } from "../interfaces/BudgetInterfaces";
import { initialStateNewBudget } from "../utils/Jsons";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 50%;
    max-width: 95%;
    max-height: 80%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;
z-index: 1000;
    @media ${device.lg} {
        width: 80%;
    } 


    @media ${device.md} {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0px;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

const DateContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 
`

interface Props {
    data: BudgetMovementProps;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setBudgetDetail?: React.Dispatch<React.SetStateAction<BudgetMovementProps>>
}

export const NewBudgetModal = ({ data, setVisible, setBudgetDetail }: Props) => {

    const { name, token } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch()

    const [isEdit] = useState<boolean>(data.id !== 0);

    const [form, setForm] = useState<BudgetMovementProps>(data);

    const [fieldIncomplete, setFieldIncomplete] = useState({
        subject: false,
        amount: false,
        responsible: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            subject: form.subject.trim() === '',
            amount: form.amount <= 0,
            responsible: form.responsible.trim() === ''
        });

        if (form.subject.trim() === '') {
            existError = true;
        }
        if (form.amount <= 0) {
            existError = true;
        }
        if (form.responsible.trim() === '') {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            let movDocument: BudgetMovementProps = initialStateNewBudget

            if (isEdit) {

                movDocument = { ...form, amount: form.type === 'expense' ? -(form.amount) : form.amount };

            } else {

                movDocument = {
                    ...form,
                    id: parseInt(getFullDate()),
                    date: parseInt(getFullDate()),
                    amount: form.type === 'expense' ? -(form.amount) : form.amount,

                }

            }

            dispatch(startLoading())

            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createMovement}`,
                { collection: "Movements", id: movDocument.id.toString(), doc: movDocument },
                { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {

                    dispatch(finishLoading())

                    if (setBudgetDetail) setBudgetDetail(form);

                    setVisible(false);

                    showMixinToast(isEdit ? 'Movimiento actualizado' : 'Nuevo movimiento creado', 'success')

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    setVisible(false);


                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));

                })

            dispatch(finishLoading())

            setVisible(false);

        } else {

            showModalMessage('Atención', 'Falta completar información.', 'error');

        }

    }

    useEffect(() => {

        console.log(form);


    }, [form])

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {isEdit ? 'Editar movimiento' : 'Nuevo movimiento'}
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />

                </HeaderContainer>

                <Input
                    label="Estado actual"
                    type="select"
                    state={`{"color": "${form.type === 'income' ? colors.ok : colors.danger}"}`}
                    value={form.type}

                    onChange={(e) => {

                        const type: "income" | "expense" = e.target.value as "income" | "expense"

                        setForm({ ...form, type });
                    }}
                >
                    <option value={'expense'}>Gasto</option>
                    <option value={'income'}>Ingreso</option>
                </Input>

                <Input
                    type="text"
                    label="Asunto"
                    value={form.subject}
                    placeholder="Asunto"
                    incomplete={fieldIncomplete.subject}
                    onChange={(e) => {

                        console.log(e);


                        setFieldIncomplete({ ...fieldIncomplete, subject: false });

                        setForm({
                            ...form,
                            subject: e.currentTarget.value
                        })
                    }}
                />

                <DateContainer>

                    <Input
                        type="number"
                        label={`Valor ($${formatPrice(form.amount)})`}
                        value={form.amount > 0 ? form.amount : ''}
                        placeholder="Valor del movimiento"
                        incomplete={fieldIncomplete.amount}
                        onChange={(e) => {

                            setFieldIncomplete({ ...fieldIncomplete, amount: false });

                            setForm({
                                ...form,
                                amount: e.target.value.trim() === '' ? 0 : parseInt(e.currentTarget.value),
                            })

                        }}
                    />

                    <Input
                        label="Método de cobro"
                        type="select"
                        value={form.paymentMethod}

                        onChange={(e) => {

                            setForm({ ...form, paymentMethod: e.target.value as PaymentMethodTypes });
                        }}
                    >
                        <option value={'Efectivo' as PaymentMethodTypes}>Efectivo</option>
                        <option value={'Mercado Pago' as PaymentMethodTypes}>Mercado Pago</option>
                        <option value={'Transferencia bancaria' as PaymentMethodTypes}>Transferencia bancaria</option>
                        <option value={'Dólares' as PaymentMethodTypes}>Dólares</option>
                        <option value={'Cheque físico' as PaymentMethodTypes}>Cheque físico</option>
                        <option value={'Echeq' as PaymentMethodTypes}>Echeq</option>
                    </Input>

                </DateContainer>

                <Input
                    type="text"
                    label="Responsable"
                    value={form.responsible}
                    placeholder="Responsable"
                    incomplete={fieldIncomplete.responsible}
                    onChange={(e) => {

                        setFieldIncomplete({ ...fieldIncomplete, responsible: false });

                        setForm({
                            ...form,
                            responsible: e.currentTarget.value
                        })
                    }}
                />

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={isEdit ? 'Editar movimiento' : 'Crear nuevo movimiento'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={false}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
