import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { showMixinToast, showModalConfirm } from "../utils/Alerts";
import { formatDateHourText, formatPrice, sortBudget } from "../utils/Utilities";
import { BudgetMovementProps } from "../interfaces/BudgetInterfaces";
import { useState } from "react";
import { WorkDetailModal } from "./WorkDetailModal";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { initialStateNewBudget, initialStateNewWork, jsonWorksList } from "../utils/Jsons";
import { NewBudgetModal } from "./NewBudgetModal";
import { LoadingComponent } from "./LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import Swal from "sweetalert2";
import { endpoints } from "../helpers/FirebaseHelper";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: relative;

    & > h1 {
        width: 100%;    
        text-align: start;
        margin-bottom: 10px;
        margin-left: 15px;

        @media ${device.sm} {
            text-align: center;
            margin-left: 0px;
        }
    }
`

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    border: 1px solid ${colors.grey_normal};
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #ddd;
    border-radius: 5px;
    
    @media ${device.sm} {
        width: 100vw;
        justify-content: center;
        border-left: none;
        border-right: none;
        border-radius: 0px;
    }
`

const ItemsContainer = styled.div`
    min-width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(200px, 1fr));
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.grey_normal};
    border-top: 1px solid ${colors.grey_normal};
    margin-bottom: 10px;

    &:first-of-type {
        padding: 0px;
        border-top: 0px solid transparent;
    }

    &:last-of-type {
        border-bottom: 1px solid transparent;
        margin-bottom: 5px;
    }
`

const BoxData = styled.div`
    width: 100%;
    height: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        & > span {
            color: ${colors.grey};
            text-decoration: line-through;
            font-size: 12px;
        }
    }

    & > button {
        margin-right: 10px;
    }

    & > button:last-of-type {
        margin-right: 0px;
    }

    & > div > button {
        font-size: 14px; 
        font-weight: 400; 
        color: ${colors.primary_dark};
        border-bottom: 1px solid ${colors.primary_dark};
    }
`

interface Props {
    // queryLoading: boolean;
    budgetList: BudgetMovementProps[];
    // setBudgetList: React.Dispatch<React.SetStateAction<BudgetMovementProps[]>>
}

export const BudgetListView = ({ budgetList }: Props) => {

    const { works: worksList, checkingStock, checkingWorks, checkingMovements } = useSelector((state: any) => state.srandamios);

    const { name, token } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch();

    const [workDetailModalVisible, setWorkDetailModalVisible] = useState(false);

    const [workDetailSelected, setWorkDetailSelected] = useState<WorkProps>(initialStateNewWork);

    const [newBudgetModalVisible, setNewBudgetModalVisible] = useState(false);

    const [newBudgetSelected, setNewBudgetSelected] = useState<BudgetMovementProps>(initialStateNewBudget);


    return (

        <MainContainer>

            <GroupContainer>

                {
                    workDetailModalVisible && (
                        <WorkDetailModal
                            data={workDetailSelected}
                            worksList={worksList}
                            setVisible={setWorkDetailModalVisible}
                        />
                    )
                }

                {
                    newBudgetModalVisible && (
                        <NewBudgetModal
                            data={newBudgetSelected}
                            setVisible={setNewBudgetModalVisible}
                        />
                    )
                }

                {
                    !checkingMovements ? (
                        <>
                            <ItemsContainer>

                                <BoxData style={{ backgroundColor: colors.grey_light, }}><Typography size='14px' bold>Fecha</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Asunto</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Valor</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Método de cobro</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Responsable</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Acciones</Typography></BoxData>

                            </ItemsContainer>

                            {
                                budgetList.length === 0 ? (

                                    <Typography size='16px' style={{ marginTop: 20, marginBottom: 15, textAlign: 'center', width: '100%' }}>No existen movimientos cargados</Typography>

                                ) : (


                                    sortBudget(budgetList).map((value) => {

                                        const { id, type, subject, amount, responsible, paymentMethod, date, workId } = value

                                        return (

                                            <ItemsContainer
                                                key={id}
                                                style={{
                                                    backgroundColor: type === 'income' ? colors.ok_light : colors.danger_light,
                                                    borderColor: type === 'income' ? colors.ok : colors.danger,
                                                }}
                                            >

                                                <BoxData><Typography size='14px'>{formatDateHourText(date)}</Typography></BoxData>
                                                <BoxData>{!workId ? (<Typography size='14px'>{subject}</Typography>) : (<div><Button type="terciary" text={subject} onClick={() => { setWorkDetailSelected(worksList.find((value: WorkProps) => { return value.id === workId }) || initialStateNewWork); setWorkDetailModalVisible(true) }} /></div>)}</BoxData>
                                                <BoxData><Typography size='14px'>${formatPrice(amount)}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{paymentMethod}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{responsible}</Typography></BoxData>

                                                <BoxData>

                                                    {
                                                        workId === null && (

                                                            <>

                                                                <Button
                                                                    type="action"
                                                                    icon={require('../images/edit.png')}
                                                                    onClick={() => {

                                                                        setNewBudgetSelected({ ...value, amount: value.amount < 0 ? value.amount * -1 : value.amount });

                                                                        setNewBudgetModalVisible(true);

                                                                    }}
                                                                />

                                                                <Button
                                                                    type="action"
                                                                    icon={require('../images/delete-primary-dark.png')}
                                                                    onClick={() => {

                                                                        const handleDelete = async () => {

                                                                            dispatch(startLoading())

                                                                            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.deleteMovement}`,
                                                                                { collection: "Movements", id: id.toString() },
                                                                                { headers: { Authorization: `Bearer ${token}` } })
                                                                                .then(() => {

                                                                                    dispatch(finishLoading())

                                                                                    showMixinToast('Movimiento eliminado', 'success')

                                                                                }).catch(async (e) => {

                                                                                    dispatch(finishLoading())

                                                                                    let msg = ''

                                                                                    if (e.response !== undefined) {

                                                                                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                                                                                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                                                                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                                                                        } else {
                                                                                            msg = e.response.data.message
                                                                                        }

                                                                                    } else {
                                                                                        msg = 'Ha ocurrido un error.'
                                                                                    }

                                                                                    console.error({ msg, error: e });

                                                                                    await axios.post(
                                                                                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                                                                        from: 'ERROR SR ANDAMIOS',
                                                                                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                                                                                        name: 'Yo',
                                                                                        phone: '',
                                                                                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                                                                                    })

                                                                                    dispatch(setError(msg));


                                                                                })

                                                                            dispatch(finishLoading())

                                                                        }

                                                                        showModalConfirm({
                                                                            title: '¿Estas seguro?',
                                                                            description: 'No vas a poder revertir esta acción.',
                                                                            icon: 'warning',
                                                                            confirmButtonText: 'Si, estoy seguro',
                                                                            handleConfirm: () => { handleDelete() }
                                                                        })


                                                                    }}
                                                                />

                                                            </>

                                                        )
                                                    }



                                                </BoxData>

                                            </ItemsContainer>
                                        )
                                    })

                                )
                            }
                        </>
                    ) :
                        (
                            <LoadingComponent />
                        )
                }




            </GroupContainer>


        </MainContainer>

    )
}