import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { Fragment, useState } from "react";
import { SelectStockListModal } from "./SelectStockListModal";
import { showMixinToast, showModalConfirm, showModalMessage } from "../utils/Alerts";
import { ActivityProps, WorkProps } from "../interfaces/WorkInterfaces";
import { formatPrice, getFullDate, sortActivity, sortPayment } from "../utils/Utilities";
import { useDispatch, useSelector } from "react-redux";
import { NewPaymentItem } from "./NewPaymentItem";
import { initialStateNewPayment } from '../utils/Jsons';
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 65%;
    max-width: 95%;
    max-height: 80%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 90%;
    } 

    @media ${device.sm} {
        width: 90%;
        max-width: 90%;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

interface Props {
    data: WorkProps;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setWorkDetail?: React.Dispatch<React.SetStateAction<WorkProps>>
}

export const SetPayedModal = ({ data, setVisible, setWorkDetail }: Props) => {

    const { name, token } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch();

    const [form, setForm] = useState<WorkProps>(data);

    const [payedForm, setPayedForm] = useState<WorkProps>(data);

    const [stockListVisible, setStockListVisible] = useState(false);

    const [fieldIncomplete, setFieldIncomplete] = useState({
        price: false
    });

    const formValidations = () => {

        let existError: boolean = false;

        let priceError = false;

        payedForm.payment.forEach((value) => { if (value.price <= 0) priceError = true })

        if (priceError) {
            setFieldIncomplete({ price: true })
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = () => {

        if (!formValidations()) {

            let priceBefore = 0
            let priceAfter = 0
            let payedBefore = 0
            let payedAfter = 0

            form.payment.forEach((value) => {

                if (value.payed) {
                    payedBefore += value.price
                } else {
                    priceBefore += value.price
                }

            })

            payedForm.payment.forEach((value) => {

                if (value.payed) {
                    payedAfter += value.price
                } else {
                    priceAfter += value.price
                }

            })

            if (priceBefore === priceAfter && payedBefore === payedAfter) {

                showMixinToast('Sin cambios', 'info')

                setVisible(false);

                return
            }

            showModalConfirm({
                title: '¿Estas seguro?',
                description: `Estas a punto de indicar que se acutaliza el cobro de $${formatPrice(payedBefore)} a $${formatPrice(payedAfter)} ${priceAfter > 0 ? `(Falta cobrar $${formatPrice(priceAfter)})` : '(TOTAL YA COBRADO)'}`,
                icon: 'warning',
                confirmButtonText: 'Confirmar',
                confirmButtonColor: colors.ok,
                cancelButtonColor: colors.danger,
                handleConfirm: async () => {

                    let activityObj: ActivityProps = {
                        id: parseInt(getFullDate()),
                        date: parseInt(getFullDate()),
                        creator: name,
                        text: `ha actualizado el cobro de $${formatPrice(payedBefore)} a $${formatPrice(payedAfter)} ${payedAfter < priceAfter ? `(Falta cobrar $${formatPrice(priceAfter)})` : '(TOTAL YA COBRADO)'}`
                    };

                    dispatch(startLoading())

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`, {
                        collection: "Works",
                        id: form.id.toString(),
                        doc: {
                            ...form,
                            payment: payedForm.payment,
                            state: (payedAfter === priceBefore && form.state.name === 'Falta cobrar') ? { id: 4, name: 'Terminado', color: colors.ok } : form.state,
                            activity: sortActivity([...form.activity, activityObj])
                        }
                    }, { headers: { Authorization: `Bearer ${token}` } }
                    )
                        .then(() => {

                            dispatch(finishLoading())

                            if (setWorkDetail) setWorkDetail({
                                ...form,
                                payment: payedForm.payment,
                                state: (payedAfter === priceBefore && form.state.name === 'Falta cobrar') ? { id: 4, name: 'Terminado', color: colors.ok } : form.state,
                                activity: sortActivity([...form.activity, activityObj])
                            });

                            showMixinToast('Cobro actualizado', 'success')

                        }).catch(async (e) => {

                            dispatch(finishLoading())

                            let msg = ''

                            if (e.response !== undefined) {

                                if (e.response.data.trace.includes('auth/id-token-expired')) {

                                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                } else {
                                    msg = e.response.data.message
                                }

                            } else {
                                msg = 'Ha ocurrido un error.'
                            }

                            console.error({ msg, error: e });

                            await axios.post(
                                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                from: 'ERROR SR ANDAMIOS',
                                to: process.env.REACT_APP_SEND_EMAIL_TO,
                                name: 'Yo',
                                phone: '',
                                text: { name, msg, error: JSON.stringify(e, null, 2) }
                            })

                            dispatch(setError(msg));


                        })

                    dispatch(finishLoading())

                    setVisible(false);

                }
            })

        } else {

            showModalMessage('Atención', 'Falta indicar cobro.', 'error');

        }

    }

    return (

        <ShadowContainer >

            {stockListVisible && (<SelectStockListModal
                key={form.id}
                form={form}
                setForm={setForm}
                setVisible={setStockListVisible}
            />)}

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Indicar cobro
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                {
                    sortPayment(payedForm.payment).map((value, index) => {

                        return (

                            <>

                                <Typography size="20px" bold style={{ width: '100%', padding: 0, paddingLeft: 5, marginTop: 50 }}>
                                    Metodo de cobro #{index + 1}
                                </Typography>

                                <NewPaymentItem
                                    key={value.id}
                                    dataItem={value}
                                    form={payedForm}
                                    setForm={setPayedForm}
                                    fieldIncomplete={fieldIncomplete}
                                    setFieldIncomplete={setFieldIncomplete}
                                />

                            </>
                        )

                    })
                }

                <Button
                    type="secondary"
                    text="Agregar forma de cobro"
                    icon={require('../images/plus-primary-dark.png')}
                    style={{ height: 45, marginBottom: 30, marginTop: 40, width: '75%', minWidth: 'fit-content' }}
                    onClick={() => {

                        setPayedForm({
                            ...payedForm,
                            payment: [
                                ...payedForm.payment,
                                {
                                    ...initialStateNewPayment,
                                    id: parseInt(getFullDate())
                                }
                            ]
                        })
                        showMixinToast('Forma de cobro agregada', 'info')
                    }}
                />

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={'Confirmar cobro'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={false}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
