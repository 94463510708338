interface Props {
    text: string
}

export const AuthTitleComponent = ({ text }: Props) => {

    return (

        <div className='auth__tittle-container'>

            <h1 className='auth__tittle'>{text}</h1>

        </div>


    )
}
