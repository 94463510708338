import { types } from '../types/types';

const initialState = {
    loading: false,
    msgError: null,
    notificationsBar: false,
    menuBar: false
}

export const uiReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }

        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        case types.uiOpenMenu:
            return {
                ...state,
                menuBar: true
            }

        case types.uiCloseMenu:
            return {
                ...state,
                menuBar: false
            }

        case types.uiOpenNotifications:
            return {
                ...state,
                notificationsBar: true
            }

        case types.uiCloseNotifications:
            return {
                ...state,
                notificationsBar: false
            }

        default:
            return state;

    }

}