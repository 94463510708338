import { useDispatch } from 'react-redux';
import { startLoginWithEmailPassword } from '../../actions/auth';
import validator from 'validator';
import { setError } from '../../actions/ui';
import { AuthTitleComponent } from '../../components/AuthTitleComponent';
import { PrimaryButtonComponent } from '../../components/PrimaryButtonComponent';
import { PrimaryInputComponent } from '../../components/PrimaryInputComponent';
import { LinkButtonComponent } from '../../components/LinkButtonComponent';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const SignInScreen = () => {

    const dispatch = useDispatch();

    const [values, setValues] = useState<{ email: string, password: string }>({
        email: '',
        password: ''
    })

    const { email, password } = values;

    const history = useHistory()

    const handleLogin = async (e: any) => {

        e.preventDefault();

        if (isFormValid()) {

            dispatch(startLoginWithEmailPassword(email, password, history))

        }

    }

    const isFormValid = () => {

        if (!validator.isEmail(email)) {
            dispatch(setError('El email no es valido'));
            return false;
        } else if (password.length < 8) {
            dispatch(setError('La contraseña debe tener un mínimo de 8 caracteres'));
            return false;
        } else {
            return true;
        }

    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleLogin(event);
        }
    };

    return (

        <>

            <AuthTitleComponent
                text={`Administrá todas tus tareas en un solo lugar`}
            />

            <PrimaryInputComponent
                label='Tu correo electrónico'
                value={email}
                placeholder='Correo electrónico'
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        email: e.target.value
                    })
                }}
            />

            <PrimaryInputComponent
                label='Tu contraseña'
                value={password}
                placeholder='Contraseña'
                onKeyDown={handleKeyDown}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        password: e.target.value
                    })
                }}
            />

            <PrimaryButtonComponent
                type='primary'
                text='Ingresar ahora'
                fullWidth
                onClick={(e: any) => handleLogin(e)}
            />

            <LinkButtonComponent
                text='¿Te olvidaste tu contraseña?'
                link='/auth/forgetPassword'
            />

            <LinkButtonComponent
                label='¿Todavía no te creaste tu cuenta?'
                text='Registrate ahora'
                link='/auth/signUp'
            />


        </>

    )
}
