import { useState, useEffect } from 'react'
import { LinkButtonComponent } from '../../components/LinkButtonComponent';
import { AuthTitleComponent } from '../../components/AuthTitleComponent';
import { PrimaryButtonComponent } from '../../components/PrimaryButtonComponent';
import { Redirect } from 'react-router-dom';
import { ParagraphComponent } from '../../components/ParagraphComponent';


export const AuthStatusScreen = () => {

    const [values] = useState<{ status: 'approved' | 'pending' | 'declined' }>({
        status: 'pending'
    })

    const { status } = values;

    const [componentTexts, setComponentTexts] = useState<{ tittle: string, icon: string, description: string, buttonText?: string, action?: () => void }>({
        tittle: '',
        icon: '',
        description: '',
        buttonText: '',
        action: () => { }
    })

    const { tittle, icon, description, buttonText, action } = componentTexts;

    useEffect(() => {

        switch (status) {

            case 'approved':
                setComponentTexts({
                    tittle: '¡Estás a punto de comenzar con tus tareas!',
                    icon: require('../../images/approved.png'),
                    description: 'El Administrador aprobó tu solicitud de ingreso.',
                    buttonText: '¡Comencemos!',
                    action: () => { <Redirect to={'/home'} /> }
                })
                break;

            case 'pending':
                setComponentTexts({
                    tittle: '¡Estás a punto de comenzar con tus tareas!',
                    icon: require('../../images/pending.png'),
                    // description: 'Ya notificamos al Administrador a cerca de tu solicitud. Te vamos a notificar a tu correo apenas tengamos novedades.'
                    description: 'Ya notificamos al Administrador a cerca de tu solicitud. Cuando tengas acceso vas a poder gestionar tus tareas sin limite.'
                })
                break;

            case 'declined':
                setComponentTexts({
                    tittle: '¡Lo sentimos!',
                    icon: require('../../images/declined.png'),
                    description: 'El Administrador rechazó tu solicitud de ingreso. Podes solicitarle acceso de vuelta.',
                    buttonText: 'Solicitar acceso nuevamente',
                    action: () => { <Redirect to={'/auth/status'} /> }
                })
                break;

        }

    }, [status])

    return (

        <>

            <AuthTitleComponent
                text={tittle}
            />

            <img
                className='general__logo'
                style={{ marginTop: 50 }}
                src={icon}
                alt='logo'
            />

            <ParagraphComponent text={description} />

            {buttonText && action && (<PrimaryButtonComponent
                type='primary'
                text={buttonText}
                fullWidth
                onClick={() => action()}
            />)}

            <LinkButtonComponent
                label='¿Querés volver mas tarde?'
                text='Vover atrás'
                link='/auth/signIn'
            />


        </>

    )
}
