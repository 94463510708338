import { css, styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { Checkbox } from "./Checkbox";
import { useState } from "react";
import { SelectStockListModal } from "./SelectStockListModal";
import { Input } from "./Input";
import { showMixinToast, showModalMessage } from "../utils/Alerts";
import { ActivityProps, HourFieldTypes, TimeTypes, WorkProps } from "../interfaces/WorkInterfaces";
import { formatDateDDmmYYYY, formatDateYYYYmmDD, formatPrice, getFullDate, getNextHalfPastHour, setDeliveryRetirementDateTextField, setLabelHourText, sortActivity, sortPayment, sortStock } from "../utils/Utilities";
import { useDispatch, useSelector } from "react-redux";
import { SelectedStockItem } from "./SelectedStockItem";
import { hoursJson, initialStateNewPayment, initialStateNewStock } from '../utils/Jsons';
import { NewPaymentItem } from "./NewPaymentItem";
import { StockProps, WorkStockProps } from "../interfaces/StockInterfaces";
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 65%;
    max-width: 95%;
    max-height: 80%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 90%;
    } 

    @media ${device.sm} {
        width: 90%;
        max-width: 90%;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

const SubContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 
`

const SectionContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    border-bottom: 1px solid ${colors.border};

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey_dark};
        margin-top: 30px;
        padding-bottom: 30px;
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

const SectionHeaderContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.border};
    padding-bottom: 10px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
        padding-left: 5px;
        color: ${colors.grey_dark};
    }

    & > button {
        color: ${colors.grey_dark};
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`


interface Props {
    data: WorkProps;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setWorkDetail?: React.Dispatch<React.SetStateAction<WorkProps>>
}

export const ExtendDaysModal = ({ data, setVisible, setWorkDetail }: Props) => {

    const { name, token } = useSelector((state: any) => state.auth);

    const { stock: stockList, checkingStock, checkingWorks } = useSelector((state: any) => state.srandamios);

    const dispatch = useDispatch();

    const [extensionsCheckbox, setExtensionsCheckbox] = useState({
        showNewDate: false,
        showNewPayment: false,
        showNewStock: false,
    })

    const [form, setForm] = useState<WorkProps>(data);

    const [extendDaysForm, setExtendDaysForm] = useState<WorkProps>({
        ...data,
        extension: {
            stock: [],
            date: data.retirementDate
        }
    });

    const [stockListVisible, setStockListVisible] = useState(false);

    const [fieldIncomplete, setFieldIncomplete] = useState({
        retirementDate: false,
        daysAmount: false,
        price: false,
        stock: false,
        extension: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        let priceError = false;

        extendDaysForm.payment.forEach((value) => { if (value.price <= 0) priceError = true })

        setFieldIncomplete({
            retirementDate: extendDaysForm.retirementDate.date <= 0,
            daysAmount: extendDaysForm.daysAmount < 0,
            price: priceError,
            stock: extendDaysForm.stock.length <= 0,
            extension: (extendDaysForm.extension && extendDaysForm.extension?.date.date <= 0) ? true : false
        });

        if (extendDaysForm.retirementDate.date <= 0) {
            existError = true;
        }
        if (extendDaysForm.daysAmount < 0) {
            existError = true;
        }
        if (priceError) {
            existError = true;
        }
        if ((extendDaysForm.extension && extendDaysForm.extension?.date.date <= 0) ? true : false) {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            let extendedStock: WorkStockProps[] = []

            let priceBefore = 0
            let priceAfter = 0
            let payedBefore = 0
            let payedAfter = 0

            form.payment.forEach((value) => {

                if (value.payed) {
                    payedBefore += value.price
                } else {
                    priceBefore += value.price
                }

            })

            extendDaysForm.payment.forEach((value) => {

                if (value.payed) {
                    payedAfter += value.price
                } else {
                    priceAfter += value.price
                }

            })

            extendDaysForm.stock.forEach((value) => {

                const founded = form.stock.find((v) => { return value.id === v.id })

                if (founded) {

                    if (founded.amount !== value.amount) {

                        console.log(value.amount);
                        console.log(founded.amount);

                        const amount = parseInt(value.amount.toString()) - parseInt(founded.amount.toString())

                        console.log(amount);

                        extendedStock = [
                            ...extendedStock,
                            {
                                ...founded,
                                amount: amount > 0 ? amount : -(amount),
                                toDeliverRetire: amount > 0 ? 'Para entregar' : 'Para retirar'
                            }
                        ]

                    }

                } else {
                    extendedStock = [
                        ...extendedStock,
                        {
                            ...value,
                            toDeliverRetire: 'Para entregar'
                        }
                    ]
                }

            })

            form.stock.forEach((value) => {

                const founded = extendDaysForm.stock.find((v) => { return value.id === v.id })

                if (!founded) {
                    extendedStock = [
                        ...extendedStock,
                        {
                            ...value,
                            toDeliverRetire: 'Para retirar'
                        }
                    ]
                }

            })

            if (!extensionsCheckbox.showNewDate
                && !extensionsCheckbox.showNewPayment
                && !extensionsCheckbox.showNewStock
            ) {

                showMixinToast('Sin cambios', 'info')

                setVisible(false);

                return
            }

            let dateText = ''
            let paymentText = ''
            let stockText = ''


            dateText = `${(extendDaysForm.daysAmount > 0 && extensionsCheckbox.showNewDate) ? `se ha modificado el plazo de alquiler de ${form.daysAmount} dias a ${(form.daysAmount + extendDaysForm.daysAmount)} dias. ` : 'se mantienen los dias de alquiler. '}
                ${(form.retirementDate.date !== extendDaysForm.retirementDate.date) || (form.retirementDate.hourStart !== extendDaysForm.retirementDate.hourStart)
                    || (form.retirementDate.hourEnd !== extendDaysForm.retirementDate.hourEnd) ? `Se ha modificado la fecha de retiro del 
                        ${formatDateDDmmYYYY(form.retirementDate.date)} (${setDeliveryRetirementDateTextField(form.retirementDate)}) al ${formatDateDDmmYYYY(extendDaysForm.retirementDate.date)} (${setDeliveryRetirementDateTextField(extendDaysForm.retirementDate)}). ` : ''}`

            paymentText = `${(priceAfter !== payedBefore && form.payment.length === extendDaysForm.payment.length) ? `Se mantiene el precio final. ` : `El precio final pasó de $${formatPrice(priceBefore)} a $${formatPrice(priceAfter)}. `}
                ${(priceAfter !== payedBefore && form.payment.length === extendDaysForm.payment.length) ? '' : `Además, se ha actualizado un cobro de $${formatPrice(payedBefore)} a $${formatPrice(payedAfter)}
                 ${priceAfter > 0 ? `(Falta cobrar $${formatPrice(priceAfter)}). ` : '(TOTAL YA COBRADO). '}`}`

            stockText = `${form.stock === extendDaysForm.stock ? `Se mantiene el stock. ` : `Se ha modificado el stock.
                ${(extendDaysForm.extension !== null) ? ` Se realizará la entrega/retiro del nuevo stock al cliente el ${formatDateDDmmYYYY(extendDaysForm.extension.date.date)} (${setDeliveryRetirementDateTextField(extendDaysForm.extension.date)}).` : ''}`}`

            let activityObj: ActivityProps = {
                id: parseInt(getFullDate()),
                date: parseInt(getFullDate()),
                creator: name,
                text: dateText + paymentText + stockText
            };

            //

            dispatch(startLoading())

            console.log(extendDaysForm.retirementDate);
            console.log(form.retirementDate);

            //

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`, {
                collection: "Works",
                id: extendDaysForm.id.toString(),
                doc: {
                    ...form,
                    retirementDate: extensionsCheckbox.showNewDate ? extendDaysForm.retirementDate : form.retirementDate,
                    daysAmount: extensionsCheckbox.showNewDate ? form.daysAmount + extendDaysForm.daysAmount : form.daysAmount,
                    payment: extensionsCheckbox.showNewPayment ? extendDaysForm.payment : form.payment,
                    activity: sortActivity([...form.activity, activityObj]),
                    stock: extensionsCheckbox.showNewStock ? extendDaysForm.stock : form.stock,
                    extension: extensionsCheckbox.showNewStock ? { date: extendDaysForm.extension ? extendDaysForm.extension.date : data.retirementDate, stock: extendedStock } : form.extension,
                }
            }, { headers: { Authorization: `Bearer ${token}` } }
            )
                .then((e) => {

                    console.log(e);

                    dispatch(finishLoading())

                    if (setWorkDetail) setWorkDetail({
                        ...form,
                        retirementDate: extensionsCheckbox.showNewDate ? extendDaysForm.retirementDate : form.retirementDate,
                        daysAmount: extensionsCheckbox.showNewDate ? form.daysAmount + extendDaysForm.daysAmount : form.daysAmount,
                        payment: extensionsCheckbox.showNewPayment ? extendDaysForm.payment : form.payment,
                        activity: sortActivity([...form.activity, activityObj]),
                        stock: extensionsCheckbox.showNewStock ? extendDaysForm.stock : form.stock,
                        extension: (extensionsCheckbox.showNewStock && extendedStock.length > 0) ? { date: extensionsCheckbox.showNewDate ? extendDaysForm.retirementDate : form.retirementDate, stock: extendedStock } : form.extension,
                    });

                    showMixinToast('Plazo extendido', 'success')

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));


                })

            //

            dispatch(finishLoading())

            setVisible(false);

            //

        } else {

            showModalMessage('Atención', 'Falta completar información.', 'error');

        }

    }

    return (

        <ShadowContainer >

            {stockListVisible && (<SelectStockListModal
                form={extendDaysForm}
                setForm={setExtendDaysForm}
                setVisible={setStockListVisible}
            />)}

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Extender plazo
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <Checkbox
                    text="Indicar modificaciones en los dias de alquiler"
                    checked={extensionsCheckbox.showNewDate}
                    key="showNewDate"
                    onClick={() => {

                        if (extensionsCheckbox.showNewDate) {

                            setExtendDaysForm({
                                ...extendDaysForm,
                                retirementDate: data.retirementDate,
                                daysAmount: data.daysAmount
                            })

                        }

                        setExtensionsCheckbox({
                            ...extensionsCheckbox,
                            showNewDate: !extensionsCheckbox.showNewDate,
                        })
                    }}
                    style={{ marginTop: 30 }}
                />

                {
                    extensionsCheckbox.showNewDate && (

                        <>

                            <Input
                                type="number"
                                label="Días de alquiler agregados"
                                value={extendDaysForm.daysAmount >= 0 ? extendDaysForm.daysAmount : ''}
                                placeholder="Cantidad de días"
                                incomplete={fieldIncomplete.daysAmount}
                                min={0}
                                onChange={(e) => {

                                    setFieldIncomplete({ ...fieldIncomplete, daysAmount: false });

                                    setExtendDaysForm({
                                        ...extendDaysForm,
                                        daysAmount: parseInt(e.currentTarget.value)
                                    })
                                }}
                            />


                            <SectionContainer fieldincomplete={''} style={{ paddingBottom: 30, marginBottom: 15 }}>

                                <SectionHeaderContainer fieldincomplete={''}>

                                    <Typography size="24px">
                                        Retiro
                                    </Typography>

                                </SectionHeaderContainer>

                                <Input
                                    type="date"
                                    label="Fecha de retiro"
                                    value={extendDaysForm.retirementDate.date > 0 ? formatDateYYYYmmDD(extendDaysForm.retirementDate.date) : ''}
                                    incomplete={fieldIncomplete.retirementDate}
                                    onChange={(e) => {

                                        const date = parseInt(e.currentTarget.value.replace(/-/g, ''));

                                        setFieldIncomplete({ ...fieldIncomplete, retirementDate: false });

                                        setExtendDaysForm({
                                            ...extendDaysForm,
                                            retirementDate: {
                                                ...extendDaysForm.retirementDate,
                                                date
                                            }
                                        })

                                    }}
                                />

                                <SubContainer>

                                    <Input
                                        label="Horario"
                                        type="select"
                                        value={extendDaysForm.retirementDate.hourType}
                                        onChange={(e) => {

                                            setExtendDaysForm({
                                                ...extendDaysForm,
                                                retirementDate: {
                                                    ...extendDaysForm.retirementDate,
                                                    hourType: e.target.value as HourFieldTypes,
                                                    hourStart: e.target.value === 'Antes de las...' ? '08:00hs' : extendDaysForm.retirementDate.hourStart,
                                                    hourEnd: e.target.value === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(extendDaysForm.retirementDate.hourStart as TimeTypes),
                                                }
                                            });
                                        }}
                                    >
                                        <option key={'Horario específico'} value={'Horario específico' as HourFieldTypes}>Horario específico</option>
                                        <option key={'Antes de las...' as HourFieldTypes} value={'Antes de las...' as HourFieldTypes}>Antes de las...</option>
                                        <option key={'Después de las...' as HourFieldTypes} value={'Después de las...' as HourFieldTypes}>Después de las...</option>
                                        <option key={'Intervalo de horas' as HourFieldTypes} value={'Intervalo de horas' as HourFieldTypes}>Intervalo de horas</option>
                                    </Input>

                                    {
                                        (extendDaysForm.retirementDate.hourType !== "Antes de las...") && (
                                            <Input
                                                label={setLabelHourText(extendDaysForm.retirementDate.hourType)}
                                                type="select"
                                                value={extendDaysForm.retirementDate.hourStart}
                                                onChange={(e) => {

                                                    setExtendDaysForm({
                                                        ...extendDaysForm,
                                                        retirementDate: {
                                                            ...extendDaysForm.retirementDate,
                                                            hourStart: e.target.value as TimeTypes,
                                                            hourEnd: extendDaysForm.retirementDate.hourType === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(e.target.value as TimeTypes),
                                                        }
                                                    });
                                                }}
                                            >
                                                {
                                                    hoursJson.map((value) => {

                                                        return <option key={value} value={value as TimeTypes}>{value}</option>
                                                    })
                                                }

                                            </Input>
                                        )
                                    }

                                    {
                                        (extendDaysForm.retirementDate.hourType === 'Intervalo de horas' || extendDaysForm.retirementDate.hourType === 'Antes de las...') && (
                                            <Input
                                                label={extendDaysForm.retirementDate.hourType === 'Antes de las...' ? extendDaysForm.retirementDate.hourType : "Hasta las..."}
                                                type="select"
                                                value={extendDaysForm.retirementDate.hourEnd}
                                                onChange={(e) => {

                                                    setExtendDaysForm({
                                                        ...extendDaysForm,
                                                        retirementDate: {
                                                            ...extendDaysForm.retirementDate,
                                                            hourStart: extendDaysForm.retirementDate.hourType === 'Antes de las...' ? '08:00hs' : extendDaysForm.retirementDate.hourStart,
                                                            hourEnd: e.target.value as TimeTypes,

                                                        }
                                                    });
                                                }}
                                            >
                                                {
                                                    hoursJson.map((value) => {

                                                        return <option key={value} value={value as TimeTypes}>{value}</option>
                                                    })
                                                }

                                            </Input>
                                        )
                                    }

                                </SubContainer>

                            </SectionContainer>

                        </>

                    )
                }

                <Checkbox
                    text="Indicar modificaciones en el cobro"
                    checked={extensionsCheckbox.showNewPayment}
                    key="showNewPayment"
                    onClick={() => {

                        if (!extensionsCheckbox.showNewPayment) {

                            setExtendDaysForm({
                                ...extendDaysForm,
                                payment: data.payment
                            })

                        }

                        setExtensionsCheckbox({
                            ...extensionsCheckbox,
                            showNewPayment: !extensionsCheckbox.showNewPayment,
                        })
                    }}
                    style={{ marginTop: 30 }}
                />

                {
                    extensionsCheckbox.showNewPayment && (

                        <SectionContainer fieldincomplete={''} style={{ marginBottom: 15 }}>

                            <SectionHeaderContainer fieldincomplete={''}>

                                <Typography size="24px">
                                    Cobro
                                </Typography>

                            </SectionHeaderContainer>


                            {
                                sortPayment(extendDaysForm.payment).map((value, index) => {

                                    return (

                                        <>

                                            <Typography size="20px" bold style={{ width: '100%', padding: 0, paddingLeft: 5, marginTop: 50 }}>
                                                Metodo de cobro #{index + 1}
                                            </Typography>

                                            <NewPaymentItem
                                                key={value.id}
                                                dataItem={value}
                                                form={extendDaysForm}
                                                setForm={setExtendDaysForm}
                                                fieldIncomplete={fieldIncomplete}
                                                setFieldIncomplete={setFieldIncomplete}
                                            />

                                        </>
                                    )

                                })
                            }

                            <Button
                                type="secondary"
                                text="Agregar forma de cobro"
                                icon={require('../images/plus-primary-dark.png')}
                                style={{ height: 45, marginBottom: 30, marginTop: 40, width: '75%', minWidth: 'fit-content' }}
                                onClick={() => {

                                    setExtendDaysForm({
                                        ...extendDaysForm,
                                        payment: [
                                            ...extendDaysForm.payment,
                                            {
                                                ...initialStateNewPayment,
                                                id: parseInt(getFullDate())
                                            }
                                        ]
                                    })

                                    showMixinToast('Forma de cobro agregada', 'info')
                                }}
                            />


                        </SectionContainer>

                    )
                }

                <Checkbox
                    text="Indicar modificaciones en el stock"
                    checked={extensionsCheckbox.showNewStock}
                    key="showNewStock"
                    onClick={() => {

                        if (!extensionsCheckbox.showNewStock) {

                            setExtendDaysForm({
                                ...extendDaysForm,
                                stock: data.stock,
                            })

                        }

                        setExtensionsCheckbox({
                            ...extensionsCheckbox,
                            showNewStock: !extensionsCheckbox.showNewStock,
                        })
                    }}
                    style={{ marginTop: 30, }}
                />

                {
                    extensionsCheckbox.showNewStock && (

                        <>

                            <SectionContainer fieldincomplete={fieldIncomplete.stock.toString()}>

                                <SectionHeaderContainer fieldincomplete={fieldIncomplete.stock.toString()}>

                                    <Typography size="24px">
                                        Stock
                                    </Typography>

                                    <Button
                                        type="terciary"
                                        text="Elegir productos"
                                        onClick={() => {
                                            setStockListVisible(true);
                                        }}
                                    />

                                </SectionHeaderContainer>

                                {
                                    extendDaysForm.stock.length > 0 ? (
                                        sortStock(extendDaysForm.stock).map((value) => {

                                            if (fieldIncomplete.stock) {
                                                setFieldIncomplete({ ...fieldIncomplete, stock: false });
                                            }

                                            const getStockProductInWork = () => {

                                                let productValue: StockProps = initialStateNewStock

                                                if (!checkingWorks && !checkingStock) {

                                                    stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

                                                }

                                                return productValue
                                            }

                                            return (

                                                <SelectedStockItem
                                                    showAmounts
                                                    showDelete
                                                    disabled={false}
                                                    data={getStockProductInWork()}
                                                    form={extendDaysForm}
                                                    setForm={setExtendDaysForm}
                                                    key={value.id}
                                                />
                                            )
                                        })
                                    )
                                        : (
                                            <Typography size="14px" bold={fieldIncomplete.stock} color={fieldIncomplete.stock ? `${colors.danger} !important` : `${colors.primary_dark} !important`}>
                                                No se agregaron productos
                                            </Typography>
                                        )
                                }

                            </SectionContainer>

                            <Typography
                                size="16px"
                                style={{
                                    color: colors.primary_dark,
                                    marginTop: 30,
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: 30,
                                    backgroundColor: colors.warning_light,
                                    border: `1px solid ${colors.warning}`,
                                    whiteSpace: 'normal',

                                }}
                            >
                                Si realizas modificaciones en el stock, se deberá indicar una fecha para entregar / retirar el nuevo stock al cliente
                            </Typography>

                            {
                                (data.stock !== extendDaysForm.stock && extendDaysForm.extension !== null) && (

                                    <>


                                        <SectionContainer fieldincomplete={''} style={{ paddingBottom: 30, marginBottom: 15 }}>

                                            <SectionHeaderContainer fieldincomplete={''}>

                                                <Typography size="20px" style={{ whiteSpace: 'normal' }}>
                                                    Fecha de la entrega/retiro del nuevo stock al cliente
                                                </Typography>

                                            </SectionHeaderContainer>

                                            <Input
                                                type="date"
                                                label="Fecha de entrega/retiro"
                                                value={(extendDaysForm.extension.date.date > 0) ? formatDateYYYYmmDD(extendDaysForm.extension.date.date) : ''}
                                                incomplete={fieldIncomplete.retirementDate}
                                                onChange={(e) => {

                                                    const date = parseInt(e.currentTarget.value.replace(/-/g, ''));

                                                    setFieldIncomplete({ ...fieldIncomplete, retirementDate: false });

                                                    if (extendDaysForm.extension !== null) {

                                                        setExtendDaysForm({
                                                            ...extendDaysForm,
                                                            extension: {
                                                                ...extendDaysForm.extension,
                                                                date: {
                                                                    ...extendDaysForm.extension.date,
                                                                    date
                                                                }
                                                            }
                                                        })

                                                    }

                                                }}
                                            />

                                            <SubContainer>

                                                <Input
                                                    label="Horario"
                                                    type="select"
                                                    value={extendDaysForm.extension.date.hourType}
                                                    onChange={(e) => {

                                                        if (extendDaysForm.extension !== null) {

                                                            setExtendDaysForm({
                                                                ...extendDaysForm,
                                                                extension: {
                                                                    ...extendDaysForm.extension,
                                                                    date: {
                                                                        ...extendDaysForm.extension.date,
                                                                        hourType: e.target.value as HourFieldTypes,
                                                                        hourStart: e.target.value === 'Antes de las...' ? '08:00hs' : extendDaysForm.extension.date.hourStart,
                                                                        hourEnd: e.target.value === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(extendDaysForm.extension.date.hourStart as TimeTypes),
                                                                    }
                                                                }
                                                            })

                                                        }

                                                    }}
                                                >
                                                    <option value={'Horario específico' as HourFieldTypes}>Horario específico</option>
                                                    <option value={'Antes de las...' as HourFieldTypes}>Antes de las...</option>
                                                    <option value={'Después de las...' as HourFieldTypes}>Después de las...</option>
                                                    <option value={'Intervalo de horas' as HourFieldTypes}>Intervalo de horas</option>
                                                </Input>

                                                {
                                                    (extendDaysForm.extension.date.hourType !== "Antes de las...") && (
                                                        <Input
                                                            label={setLabelHourText(extendDaysForm.extension.date.hourType)}
                                                            type="select"
                                                            value={extendDaysForm.extension.date.hourStart}
                                                            onChange={(e) => {

                                                                if (extendDaysForm.extension !== null) {

                                                                    setExtendDaysForm({
                                                                        ...extendDaysForm,
                                                                        extension: {
                                                                            ...extendDaysForm.extension,
                                                                            date: {
                                                                                ...extendDaysForm.extension.date,
                                                                                hourStart: e.target.value as TimeTypes,
                                                                                hourEnd: extendDaysForm.extension.date.hourType === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(e.target.value as TimeTypes),
                                                                            }
                                                                        }
                                                                    })

                                                                }

                                                            }}
                                                        >
                                                            {
                                                                hoursJson.map((value) => {

                                                                    return <option key={value} value={value as TimeTypes}>{value}</option>
                                                                })
                                                            }

                                                        </Input>
                                                    )
                                                }

                                                {
                                                    (extendDaysForm.extension.date.hourType === 'Intervalo de horas' || extendDaysForm.extension.date.hourType === 'Antes de las...') && (
                                                        <Input
                                                            label={extendDaysForm.extension.date.hourType === 'Antes de las...' ? extendDaysForm.extension.date.hourType : "Hasta las..."}
                                                            type="select"
                                                            value={extendDaysForm.extension.date.hourEnd}
                                                            onChange={(e) => {

                                                                if (extendDaysForm.extension !== null) {

                                                                    setExtendDaysForm({
                                                                        ...extendDaysForm,
                                                                        extension: {
                                                                            ...extendDaysForm.extension,
                                                                            date: {
                                                                                ...extendDaysForm.extension.date,
                                                                                hourStart: extendDaysForm.extension.date.hourType === 'Antes de las...' ? '08:00hs' : extendDaysForm.extension.date.hourStart,
                                                                                hourEnd: e.target.value as TimeTypes,
                                                                            }
                                                                        }
                                                                    })

                                                                }

                                                            }}
                                                        >
                                                            {
                                                                hoursJson.map((value) => {

                                                                    return <option key={value} value={value as TimeTypes}>{value}</option>
                                                                })
                                                            }

                                                        </Input>
                                                    )
                                                }

                                            </SubContainer>

                                        </SectionContainer>

                                    </>

                                )
                            }
                        </>

                    )
                }

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={'Confirmar nuevo plazo'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={false}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
