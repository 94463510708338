import { css, styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useState } from "react";
import { SelectedStockItem } from "./SelectedStockItem";
import { SelectStockListModal } from "./SelectStockListModal";
import { Input } from "./Input";
import { formatDateYYYYmmDD, getFullDate, getNextHalfPastHour, setLabelHourText, sortActivity, sortPayment } from "../utils/Utilities";
import { useDispatch, useSelector } from "react-redux";
import { showMixinToast, showModalMessage } from "../utils/Alerts";
import { ActivityProps, HourFieldTypes, StateWorkProps, TimeTypes, WorkProps } from "../interfaces/WorkInterfaces";
import { NewPaymentItem } from "./NewPaymentItem";
import { hoursJson, initialStateNewPayment, initialStateNewStock, initialStateNewWork } from "../utils/Jsons";
import { StockProps } from "../interfaces/StockInterfaces";
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 65%;
    max-width: 95%;
    max-height: 90%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 90%;
    } 


    @media ${device.md} {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0px;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

const SubContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 
`

const SectionContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    border-bottom: 1px solid ${colors.border};

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey_dark};
        margin-top: 30px;
        padding-bottom: 30px;
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

const SectionHeaderContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.border};
    padding-bottom: 10px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: left;
        padding-left: 5px;
        color: ${colors.grey_dark};
    }

    & > button {
        color: ${colors.grey_dark};
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

interface Props {
    data: WorkProps;
    worksList: WorkProps[];
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setWorkDetail?: React.Dispatch<React.SetStateAction<WorkProps>>
}

export const NewWorkModal = ({ data, setVisible, setWorkDetail }: Props) => {

    const { name, token } = useSelector((state: any) => state.auth);

    const { stock: stockList, checkingStock, checkingWorks } = useSelector((state: any) => state.srandamios);

    const [isEdit] = useState<boolean>(data.id !== 0);

    const [form, setForm] = useState<WorkProps>(data);

    const [stockListVisible, setStockListVisible] = useState(false);

    const [fieldIncomplete, setFieldIncomplete] = useState({
        client: false,
        address: false,
        deliveryDate: false,
        retirementDate: false,
        daysAmount: false,
        price: false,
        stock: false,
    })

    const dispatch = useDispatch()

    const formValidations = () => {

        let existError: boolean = false;

        let priceError = false;

        form.payment.forEach((value) => { if (value.price <= 0) priceError = true })

        setFieldIncomplete({
            ...fieldIncomplete,
            client: form.client.trim() === '',
            address: form.address.trim() === '',
            deliveryDate: form.deliveryDate.date <= 0,
            retirementDate: form.retirementDate.date <= 0,
            daysAmount: form.daysAmount <= 0,
            price: priceError,
            stock: form.stock.length <= 0
        });

        if (form.client.trim() === '') {
            existError = true;
        }
        if (form.address.trim() === '') {
            existError = true;
        }
        if (form.deliveryDate.date <= 0) {
            existError = true;
        }
        if (form.retirementDate.date <= 0) {
            existError = true;
        }
        if (form.daysAmount <= 0) {
            existError = true;
        }
        if (priceError) {
            existError = true;
        }
        if (form.stock.length <= 0) {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            let activityObj: ActivityProps = {
                id: parseInt(getFullDate()),
                date: parseInt(getFullDate()),
                creator: name,
                text: ''
            };

            let workDocument: WorkProps = initialStateNewWork

            if (isEdit) {

                workDocument = { ...form, activity: sortActivity([...form.activity, { ...activityObj, text: 'ha modificado datos del trabajo' }]) };

            } else {

                workDocument = {
                    ...form,
                    id: parseInt(getFullDate()),
                    createdDate: parseInt(getFullDate()),
                    activity: sortActivity([...form.activity, { ...activityObj, text: 'ha creado el trabajo' }]),
                    payment: form.state.name === 'Terminado' ? [...form.payment.map((value) => { return { ...value, payed: true } })] : form.payment
                }
            }

            // ======================================================

            dispatch(startLoading())

            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
                { collection: "Works", id: workDocument.id.toString(), doc: workDocument },
                { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {

                    dispatch(finishLoading())

                    if (setWorkDetail) setWorkDetail({ ...form, activity: sortActivity([...form.activity, { ...activityObj, text: 'ha modificado datos del trabajo' }]) });

                    showMixinToast(isEdit ? 'Trabajo actualizado' : 'Nuevo trabajo creado', 'success')

                }).catch(async (e) => {


                    dispatch(finishLoading())

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));


                })

            dispatch(finishLoading())

            setVisible(false);

        } else {

            showModalMessage('Atención', 'Falta completar información.', 'error');

        }

    }

    return (

        <ShadowContainer >

            {stockListVisible && (<SelectStockListModal
                form={form}
                setForm={setForm}
                setVisible={setStockListVisible}
            />)}

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {isEdit ? 'Editar trabajo' : 'Nuevo trabajo'}
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />

                </HeaderContainer>

                <SubContainer>

                    <Input
                        type="text"
                        label="Cliente"
                        value={form.client}
                        placeholder="Identificador del cliente"
                        incomplete={fieldIncomplete.client}
                        onChange={(e) => {

                            setFieldIncomplete({ ...fieldIncomplete, client: false });

                            setForm({
                                ...form,
                                client: e.currentTarget.value
                            })
                        }}
                    />

                    <Input
                        type="text"
                        label="Dirección"
                        value={form.address}
                        placeholder="Dirección de entrega y retiro"
                        incomplete={fieldIncomplete.address}
                        onChange={(e) => {

                            setFieldIncomplete({ ...fieldIncomplete, address: false });

                            setForm({
                                ...form,
                                address: e.currentTarget.value
                            })
                        }}
                    />

                </SubContainer>

                <SubContainer>

                    <Input
                        label="Estado actual"
                        type="select"
                        state={`{"id": ${form.state.id}, "name": "${form.state.name}", "color": "${form.state.color}"}`}
                        value={`{"id": ${form.state.id}, "name": "${form.state.name}", "color": "${form.state.color}"}`}

                        onChange={(e) => {

                            const state: StateWorkProps = JSON.parse(e.target.value);

                            setForm({ ...form, state });
                        }}
                    >
                        <option value={`{"id": 0, "name": "A confirmar", "color": "${colors.to_confirm}"}`}>A confirmar</option>
                        <option value={`{"id": 1, "name": "Para entregar", "color": "${colors.primary}"}`}>Para entregar</option>
                        <option value={`{"id": 2, "name": "Para retirar", "color": "${colors.warning}"}`}>Para retirar</option>
                        <option value={`{"id": 3, "name": "Falta cobrar", "color": "${colors.danger}"}`}>Falta cobrar</option>
                        <option value={`{"id": 4, "name": "Terminado", "color": "${colors.ok}"}`}>Terminado</option>
                    </Input>


                    <Input
                        type="number"
                        label="Días de alquiler"
                        value={form.daysAmount > 0 ? form.daysAmount : ''}
                        placeholder="Cantidad de días"
                        incomplete={fieldIncomplete.daysAmount}
                        onChange={(e) => {

                            setFieldIncomplete({ ...fieldIncomplete, daysAmount: false });

                            setForm({
                                ...form,
                                daysAmount: e.target.value.trim() === '' ? 0 : parseInt(e.currentTarget.value)
                            })
                        }}
                    />

                </SubContainer>

                <SectionContainer fieldincomplete={''} style={{ paddingBottom: 30, marginBottom: 15 }}>

                    <SectionHeaderContainer fieldincomplete={''}>

                        <Typography size="24px">
                            Entrega
                        </Typography>

                    </SectionHeaderContainer>

                    <Input
                        type="date"
                        label="Fecha de entrega"
                        value={form.deliveryDate.date > 0 ? formatDateYYYYmmDD(form.deliveryDate.date) : ''}
                        incomplete={fieldIncomplete.deliveryDate}
                        onChange={(e) => {

                            const date = parseInt(e.currentTarget.value.replace(/-/g, ''));

                            setFieldIncomplete({ ...fieldIncomplete, deliveryDate: false });

                            setForm({
                                ...form,
                                deliveryDate: {
                                    ...form.deliveryDate,
                                    date
                                }
                            })

                        }}
                    />

                    <SubContainer>

                        <Input
                            label="Horario"
                            type="select"
                            value={form.deliveryDate.hourType}
                            onChange={(e) => {

                                setForm({
                                    ...form,
                                    deliveryDate: {
                                        ...form.deliveryDate,
                                        hourType: e.target.value as HourFieldTypes,
                                        hourStart: e.target.value === 'Antes de las...' ? '08:00hs' : form.deliveryDate.hourStart,
                                        hourEnd: e.target.value === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(form.deliveryDate.hourStart as TimeTypes),
                                    }
                                });
                            }}
                        >
                            <option value={'Horario específico' as HourFieldTypes}>Horario específico</option>
                            <option value={'Antes de las...' as HourFieldTypes}>Antes de las...</option>
                            <option value={'Después de las...' as HourFieldTypes}>Después de las...</option>
                            <option value={'Intervalo de horas' as HourFieldTypes}>Intervalo de horas</option>
                        </Input>

                        {
                            (form.deliveryDate.hourType !== "Antes de las...") && (
                                <Input
                                    label={setLabelHourText(form.deliveryDate.hourType)}
                                    type="select"
                                    value={form.deliveryDate.hourStart}
                                    onChange={(e) => {

                                        setForm({
                                            ...form,
                                            deliveryDate: {
                                                ...form.deliveryDate,
                                                hourStart: e.target.value as TimeTypes,
                                                hourEnd: form.deliveryDate.hourType === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(e.target.value as TimeTypes),
                                            }
                                        });
                                    }}
                                >
                                    {
                                        hoursJson.map((value) => {

                                            return <option key={value} value={value as TimeTypes}>{value}</option>
                                        })
                                    }

                                </Input>
                            )
                        }

                        {
                            (form.deliveryDate.hourType === 'Intervalo de horas' || form.deliveryDate.hourType === 'Antes de las...') && (
                                <Input
                                    label={form.deliveryDate.hourType === 'Antes de las...' ? form.deliveryDate.hourType : "Hasta las..."}
                                    type="select"
                                    value={form.deliveryDate.hourEnd}
                                    onChange={(e) => {

                                        setForm({
                                            ...form,
                                            deliveryDate: {
                                                ...form.deliveryDate,
                                                hourStart: form.deliveryDate.hourType === 'Antes de las...' ? '08:00hs' : form.deliveryDate.hourStart,
                                                hourEnd: e.target.value as TimeTypes,

                                            }
                                        });
                                    }}
                                >
                                    {
                                        hoursJson.map((value) => {

                                            return <option key={value} value={value as TimeTypes}>{value}</option>
                                        })
                                    }

                                </Input>
                            )
                        }

                    </SubContainer>

                </SectionContainer>


                <SectionContainer fieldincomplete={''} style={{ paddingBottom: 30, marginBottom: 15 }}>

                    <SectionHeaderContainer fieldincomplete={''}>

                        <Typography size="24px">
                            Retiro
                        </Typography>

                    </SectionHeaderContainer>

                    <Input
                        type="date"
                        label="Fecha de retiro"
                        value={form.retirementDate.date > 0 ? formatDateYYYYmmDD(form.retirementDate.date) : ''}
                        incomplete={fieldIncomplete.retirementDate}
                        onChange={(e) => {

                            const date = parseInt(e.currentTarget.value.replace(/-/g, ''));

                            setFieldIncomplete({ ...fieldIncomplete, retirementDate: false });

                            setForm({
                                ...form,
                                retirementDate: {
                                    ...form.retirementDate,
                                    date
                                }
                            })

                        }}
                    />

                    <SubContainer>

                        <Input
                            label="Horario"
                            type="select"
                            value={form.retirementDate.hourType}
                            onChange={(e) => {

                                setForm({
                                    ...form,
                                    retirementDate: {
                                        ...form.retirementDate,
                                        hourType: e.target.value as HourFieldTypes,
                                        hourStart: e.target.value === 'Antes de las...' ? '08:00hs' : form.retirementDate.hourStart,
                                        hourEnd: e.target.value === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(form.retirementDate.hourStart as TimeTypes),
                                    }
                                });
                            }}
                        >
                            <option value={'Horario específico' as HourFieldTypes}>Horario específico</option>
                            <option value={'Antes de las...' as HourFieldTypes}>Antes de las...</option>
                            <option value={'Después de las...' as HourFieldTypes}>Después de las...</option>
                            <option value={'Intervalo de horas' as HourFieldTypes}>Intervalo de horas</option>
                        </Input>

                        {
                            (form.retirementDate.hourType !== "Antes de las...") && (
                                <Input
                                    label={setLabelHourText(form.retirementDate.hourType)}
                                    type="select"
                                    value={form.retirementDate.hourStart}
                                    onChange={(e) => {

                                        setForm({
                                            ...form,
                                            retirementDate: {
                                                ...form.retirementDate,
                                                hourStart: e.target.value as TimeTypes,
                                                hourEnd: form.retirementDate.hourType === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(e.target.value as TimeTypes),
                                            }
                                        });
                                    }}
                                >
                                    {
                                        hoursJson.map((value) => {

                                            return <option key={value} value={value as TimeTypes}>{value}</option>
                                        })
                                    }

                                </Input>
                            )
                        }

                        {
                            (form.retirementDate.hourType === 'Intervalo de horas' || form.retirementDate.hourType === 'Antes de las...') && (
                                <Input
                                    label={form.retirementDate.hourType === 'Antes de las...' ? form.retirementDate.hourType : "Hasta las..."}
                                    type="select"
                                    value={form.retirementDate.hourEnd}
                                    onChange={(e) => {

                                        setForm({
                                            ...form,
                                            retirementDate: {
                                                ...form.retirementDate,
                                                hourStart: form.retirementDate.hourType === 'Antes de las...' ? '08:00hs' : form.retirementDate.hourStart,
                                                hourEnd: e.target.value as TimeTypes,

                                            }
                                        });
                                    }}
                                >
                                    {
                                        hoursJson.map((value) => {

                                            return <option key={value} value={value as TimeTypes}>{value}</option>
                                        })
                                    }

                                </Input>
                            )
                        }

                    </SubContainer>

                </SectionContainer>

                <SectionContainer fieldincomplete={''} style={{ marginBottom: 15 }}>

                    <SectionHeaderContainer fieldincomplete={''}>

                        <Typography size="24px">
                            Cobro
                        </Typography>

                    </SectionHeaderContainer>


                    {
                        sortPayment(form.payment).map((value, index) => {

                            return (

                                <>

                                    <Typography size="20px" bold style={{ width: '100%', padding: 0, paddingLeft: 5, marginTop: 50 }}>
                                        Metodo de cobro #{index + 1}
                                    </Typography>

                                    <NewPaymentItem
                                        key={value.id}
                                        dataItem={value}
                                        form={form}
                                        setForm={setForm}
                                        fieldIncomplete={fieldIncomplete}
                                        setFieldIncomplete={setFieldIncomplete}
                                    />

                                </>
                            )

                        })
                    }

                    <Button
                        type="secondary"
                        text="Agregar forma de cobro"
                        icon={require('../images/plus-primary-dark.png')}
                        style={{ height: 45, marginBottom: 30, marginTop: 40, width: '75%', minWidth: 'fit-content' }}
                        onClick={() => {

                            setForm({
                                ...form,
                                payment: [
                                    ...form.payment,
                                    {
                                        ...initialStateNewPayment,
                                        id: parseInt(getFullDate())
                                    }
                                ]
                            })
                            showMixinToast('Forma de cobro agregada', 'info')
                        }}
                    />


                </SectionContainer>

                <SectionContainer fieldincomplete={fieldIncomplete.stock.toString()}>

                    <SectionHeaderContainer fieldincomplete={fieldIncomplete.stock.toString()}>

                        <Typography size="24px">
                            Stock
                        </Typography>

                        <Button
                            type="terciary"
                            text="Elegir productos"
                            onClick={() => {
                                setStockListVisible(true);
                            }}
                        />

                    </SectionHeaderContainer>

                    {
                        form.stock.length > 0 ? (

                            form.stock.map((value) => {

                                if (fieldIncomplete.stock) {
                                    setFieldIncomplete({ ...fieldIncomplete, stock: false });
                                }

                                const getStockProductInWork = () => {

                                    let productValue: StockProps = initialStateNewStock

                                    if (!checkingWorks && !checkingStock) {

                                        stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

                                    }

                                    return productValue
                                }

                                return (

                                    <SelectedStockItem
                                        showAmounts={false}
                                        showDelete
                                        disabled={false}
                                        data={getStockProductInWork()}
                                        form={form}
                                        setForm={setForm}
                                        key={value.id}
                                    />

                                )
                            })
                        )
                            : (
                                <Typography size="14px" bold={fieldIncomplete.stock} color={fieldIncomplete.stock ? `${colors.danger} !important` : `${colors.primary_dark} !important`}>
                                    No se agregaron productos
                                </Typography>
                            )
                    }

                </SectionContainer>


                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={isEdit ? 'Editar trabajo' : 'Crear nuevo trabajo'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={false}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
