import { Typography } from '../../components/Typography';
import { PrimaryScreensContainer } from '../../components/PrimaryScreensContainer';
import { BoxContainer } from '../../components/BoxContainer';
import { PrimaryInputComponent } from '../../components/PrimaryInputComponent';
import { useState } from 'react';
import { Icon } from '../../components/Icon';
import { styled } from 'styled-components';
import { GenericButton } from '../../components/Button';
import { colors, device } from '../../styles/stylesConfig';
import { login, startLogout } from '../../actions/auth';
import { showModalConfirm } from '../../utils/Alerts';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { finishLoading, setError, startLoading } from '../../actions/ui';
import Swal from 'sweetalert2';
import { endpoints } from '../../helpers/FirebaseHelper';
import { getAuth } from 'firebase/auth';
import { StoreProps } from '../../interfaces/ReducerInterfaces';

const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ExitButton = styled(GenericButton)`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.primary_dark};
    font-weight: normal;
    margin-top: 30px;
    background-color: ${colors.danger_light};
    border-color: ${colors.danger};
    width: fit-content;
    cursor: pointer;

    @media ${device.sm} {
        width: 100%;
    }
`

export const ProfileScreen = () => {

    const dispatch = useDispatch()

    const { uid, name, token, expirationTime } = useSelector((state: StoreProps) => state.auth);

    const [form, setForm] = useState({
        fullname: name
    });

    const handleUpdateFullName = async () => {

        dispatch(startLoading())

        await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.updateFullname}`,
            { displayName: form.fullname },
            { headers: { Authorization: `Bearer ${token}` } })
            .then(async (value) => {

                dispatch(finishLoading())

                dispatch(login({
                    uid,
                    name: form.fullname,
                    token,
                    expirationTime
                }))

                Swal.fire('¡Listo!', value.data.message, 'success');


            }).catch(async (e) => {

                dispatch(finishLoading())

                let msg = ''

                if (e.response !== undefined) {

                    if (e.response.data.trace.includes('auth/id-token-expired')) {

                        msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                            "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                    } else {
                        msg = e.response.data.message
                    }

                } else {
                    msg = 'Ha ocurrido un error.'
                }

                console.error({ msg, error: e });

                await axios.post(
                    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                    from: 'ERROR SR ANDAMIOS',
                    to: process.env.REACT_APP_SEND_EMAIL_TO,
                    name: 'Yo',
                    phone: '',
                    text: { name, msg, error: JSON.stringify(e, null, 2) }
                })

                dispatch(setError(msg));

            })

    }

    const handleLogOut = async () => {

        dispatch(startLoading())

        dispatch(startLogout())

        dispatch(finishLoading())
    }

    return (

        <PrimaryScreensContainer type='secondary' menuSelected='Perfil'>

            <Typography
                size='28px'
                bold
            >
                Datos personales
            </Typography>

            <Typography
                size='14px'
                style={{ marginTop: 15 }}
            >
                ¡Mantené actualizada la información de tu perfil! De esa manera vas a tener tus datos protegidos y al día.
            </Typography>

            <BoxContainer
                primaryButtonText='Guardar'
                primaryDisabled={form.fullname !== null && form.fullname.trim() === name}
                secondaryButtonText='Cancelar'
                onClickPrimary={() => { handleUpdateFullName() }}
                onClickSecondary={() => { setForm({ fullname: name }) }}

            >

                <Title>

                    <Icon
                        size="M"
                        src={require('../../images/user-primary.png')}
                    />

                    <Typography
                        size='20px'
                        bold
                        style={{ marginLeft: 10 }}
                    >
                        Información general
                    </Typography>

                </Title>

                <PrimaryInputComponent
                    label='Tu nombre y apellido'
                    value={form.fullname}
                    onChange={(e) => setForm({ fullname: e.target.value })}
                />

            </BoxContainer>

            <BoxContainer
            >

                <Title>

                    <Icon
                        size="M"
                        src={require('../../images/signout-primary.png')}
                    />

                    <Typography
                        size='20px'
                        bold
                        style={{ marginLeft: 10 }}
                    >
                        Cerrar Sesión
                    </Typography>

                </Title>

                <Typography
                    size='14px'
                    style={{ marginTop: 15, textAlign: 'start' }}
                >
                    ¿Querés cerrar sesión o ingresar con otra cuenta?
                </Typography>

                <ExitButton
                    onClick={() => {

                        showModalConfirm({
                            title: 'Atención',
                            description: 'Estas a punto de cerrar sesión.',
                            icon: 'warning',
                            confirmButtonText: 'Continuar',
                            handleConfirm: () => { handleLogOut() }
                        })
                    }}

                >Cerrar sesión de mi cuenta</ExitButton>

            </BoxContainer>

        </PrimaryScreensContainer>

    )
}
