import { styled } from 'styled-components';
import { colors, device } from '../styles/stylesConfig';
import { Menu } from './Menu';
import { HeaderBar } from './HeaderBar';
import { ReactNode } from 'react';

const MainContainer = styled.div<{ type: 'primary' | 'secondary' }>`
    display: flex;
    width: 100%;
    min-height: 100%;
    position: relative;
    
    & > div:first-child {
        background-color: ${props => props.type === 'primary' ? 'rgba(222, 239, 254, 0.2)' : 'white'};
    }
`

const SectionScreenContainer = styled.div<{ type: 'primary' | 'secondary' }>`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 85%;
    border-left: 1px solid ${colors.grey_normal};
    box-shadow: ${props => props.type === 'primary' ? '0px 3px 15px #00000026' : 'none'};
    background-color: ${props => props.type === 'primary' ? 'white' : colors.grey_light};
    @media ${device.lg} {
        width: 100%;
        box-shadow: none;
        border-left: none;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    padding-top: 170px;

    & > h1:first-child {
        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.sm} {
        padding-top: 150px;
        align-items: center;
        text-align: center;
    }

    @media ${device.xs} {
        padding-top: 120px;
    } 
`

interface Props {
    type: 'primary' | 'secondary'
    menuSelected: 'Inicio' | 'Proyectos' | 'Perfil' | ''
    children?: ReactNode
}

export const PrimaryScreensContainer = ({ type, menuSelected, children }: Props) => {

    return (

        <MainContainer type={type}>

            <Menu type='desktop' selected={menuSelected} />

            <SectionScreenContainer type={type}>

                <HeaderBar />

                <ContentContainer>

                    {children}

                </ContentContainer>

            </SectionScreenContainer>

        </MainContainer>

    )
}
