import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { HomeScreen } from "../screens/private/HomeScreen";
import { ProfileScreen } from "../screens/private/ProfileScreen";
import { ProyectsScreen } from "../screens/private/ProyectsScreen";
import { ProyectScreen } from "../screens/private/ProyectScreen";
import { useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase/firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { removeCheckingSrAndamiosMovements, removeCheckingSrAndamiosStock, removeCheckingSrAndamiosWorks, saveSrAndamiosMovements, saveSrAndamiosStock, saveSrAndamiosWorks } from "../actions/srAndamios";
import { login } from "../actions/auth";
import { getAuth } from "firebase/auth";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import { formatExpirationTime, getFullDate } from "../utils/Utilities";
import { finishLoading, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";

export const PrivateRouter = () => {

    const { name, uid, expirationTime } = useSelector((state: StoreProps) => state.auth);

    const dispatch = useDispatch();

    const auth = getAuth()

    useEffect(() => {

        const handleEvent = async () => {

            if (expirationTime && expirationTime < parseInt(getFullDate())) {

                dispatch(startLoading())

                const tokenResult = await auth.currentUser?.getIdTokenResult(true);

                if (tokenResult)
                    dispatch(login({
                        uid,
                        name,
                        token: tokenResult.token,
                        expirationTime: formatExpirationTime(tokenResult.expirationTime)
                    }))

                dispatch(finishLoading())

            }
        };

        window.addEventListener('click', handleEvent);
        window.addEventListener('keydown', handleEvent);

        return () => {
            window.removeEventListener('click', handleEvent);
            window.removeEventListener('keydown', handleEvent);
        };
    }, [auth.currentUser, dispatch, expirationTime, name, uid]);


    function workSnapshot(snapshot: any) {

        let allWorks = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveSrAndamiosWorks(allWorks));

        dispatch(removeCheckingSrAndamiosWorks());

    }

    function stockSnapshot(snapshot: any) {

        let allProducts = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveSrAndamiosStock(allProducts));

        dispatch(removeCheckingSrAndamiosStock());

    }

    function movementsSnapshot(snapshot: any) {

        let allMovements = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });


        dispatch(saveSrAndamiosMovements(allMovements));

        dispatch(removeCheckingSrAndamiosMovements());

    }

    useEffect(() => {

        onSnapshot(collection(firestore, "Works"), workSnapshot);

        onSnapshot(collection(firestore, "Stock"), stockSnapshot);

        onSnapshot(collection(firestore, "Movements"), movementsSnapshot);

    }, [movementsSnapshot, stockSnapshot, workSnapshot]);

    // useEffect(() => {

    //     const intervalId = setInterval(async () => {

    //         const { currentUser: user } = getAuth();

    //         await user!.getIdTokenResult(true).then((jwt) => {

    //             dispatch(login({
    //                 uid: user!.uid,
    //                 name: user!.displayName,
    //                 token: jwt.token,
    //                 expirationTime: formatExpirationTime(jwt.expirationTime)
    //             }))

    //         }).catch(async (e) => {

    //             console.error({ msg: 'Error al actualizar el token', error: e });

    //             await axios.post(
    //                 `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
    //                 from: 'ERROR SR ANDAMIOS',
    //                 to: process.env.REACT_APP_SEND_EMAIL_TO,
    //                 name: 'Yo',
    //                 phone: '',
    //                 text: { name: user!.displayName, msg: 'Error al actualizar el token', error: JSON.stringify(e, null, 2) }
    //             })

    //         })


    //     }, 180000);

    //     return () => clearInterval(intervalId);
    // }, []);


    return (

        <Switch>

            <Route
                exact
                path='/home'
                component={HomeScreen}
            />

            <Route
                exact
                path='/profile'
                component={ProfileScreen}
            />

            <Route
                exact
                path='/proyects'
                component={ProyectsScreen}
            />

            <Route
                exact
                path='/proyects/:id(1|2)/:page(works|calendar|stock|budget)'
                component={ProyectScreen}
            />

            <Redirect to='/home' />

        </Switch>

    )
}
