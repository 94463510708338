import { Typography } from '../../components/Typography';
import { PrimaryScreensContainer } from '../../components/PrimaryScreensContainer';
import { ButtonCardsContainer } from './HomeScreen';
import { ButtonCards } from '../../components/ButtonCards';

export const ProyectsScreen = () => {

    const directAccessButtons = [
        {
            text: 'SR Andamios',
            icon: require('../../images/bigote.png'),
            goTo: '/proyects/2/works'
        }
    ]

    return (

        <PrimaryScreensContainer type='secondary' menuSelected='Proyectos'>

            <Typography
                size='28px'
                bold
            >
                Proyectos
            </Typography>

            <Typography
                size='14px'
                style={{ marginTop: 15 }}
            >
                Acá van a aparecer tus proyectos y en los que seas miembro. Entrá para poder ver más a cerca de las tareas en curso.
            </Typography>

            <ButtonCardsContainer style={{ marginTop: 30 }}>


                {
                    directAccessButtons?.map((button) => (
                        <ButtonCards
                            icon={button.icon}
                            text={button.text}
                            key={button.text}
                            goTo={button.goTo}
                        />
                    ))
                }

            </ButtonCardsContainer>

        </PrimaryScreensContainer>

    )
}
