
interface Props {
    type: 'primary' | 'grey'
    text: string
    disabled?: boolean
    fullWidth?: boolean
    onClick: (e: any) => void
}

export const PrimaryButtonComponent = ({ text, type, disabled, fullWidth, onClick }: Props) => {

    return (

        <input
            type="submit"
            className={`btn ${type === 'primary' ? 'btn-primary' : 'btn-secondary'} ${fullWidth && 'btn-block'}`}
            value={text}
            disabled={disabled}
            onClick={(e) => onClick(e)}
        />

    )
}
