import {
    getAuth,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth';
import { types } from '../types/types';
import { finishLoading, setError, startLoading } from './ui';
import Swal from 'sweetalert2'
import axios from 'axios';
import { endpoints } from '../helpers/FirebaseHelper';
import { AuthReducerProps } from '../interfaces/ReducerInterfaces';
import { formatExpirationTime } from '../utils/Utilities';

// REGISTRARSE CON EMAIL Y CONTRASEÑA //

export const startRegisterWithEmailPassword = (email: any, password: any, name: any, history: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createUser}`,
            { email, password, passwordConfirm: password, fullname: name },
            {
                headers: {
                    "Content-Type": "application/json",
                    "access-control-allow-origin": "http://localhost:3000/proyects/2/works"
                }
            })
            .then((value) => {

                dispatch(finishLoading())

                Swal.fire('¡Bienvenido/a, ' + value.data.body.user.displayName + "!", "Le hemos solicitado acceso al administrador. Cuando te permita ingresar vas a poder gestionar tus tareas sin limite.", 'success')

                history.push('/auth/status')

            }).catch(async (e: any) => {

                console.log(e.response.data.trace);
                console.log(e.response.data.message);

                dispatch(finishLoading())

                let msg = ''

                if (e.response !== undefined) {

                    if (e.response.data.trace.includes('auth/id-token-expired')) {

                        msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                            "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                    } else {

                        if (e.response.data.trace.includes('auth/email-already-in-use') || e.response.data.trace.includes('Error: The email address is already in use by another account')) {
                            msg = 'El email ya se encuentra registrado.'
                        } else {
                            msg = e.response.data.message
                        }

                    }

                } else {
                    msg = 'Ha ocurrido un error.'
                }

                console.error({ msg, error: e });

                await axios.post(
                    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                    from: 'ERROR SR ANDAMIOS',
                    to: process.env.REACT_APP_SEND_EMAIL_TO,
                    name: 'Yo',
                    phone: '',
                    text: { name, msg, error: JSON.stringify(e, null, 2) }
                })

                dispatch(setError(msg));

            })

        dispatch(finishLoading())

    }

}

// INGRESAR CON EMAIL Y CONTRASEÑA //

export const startLoginWithEmailPassword = (email: any, password: any, history: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const auth = getAuth();

        await signInWithEmailAndPassword(auth, email, password)
            .then(async ({ user }) => {

                const tokenResult = await user.getIdTokenResult(true)

                dispatch(login({
                    uid: user.uid,
                    name: user.displayName,
                    token: tokenResult.token,
                    expirationTime: formatExpirationTime(tokenResult.expirationTime)
                }))

                dispatch(finishLoading())

                await Swal.fire('¡Excelente!', '¡Bienvenido/a nuevamente, ' + user.displayName + "!", 'success')

            })
            .catch(e => {

                dispatch(finishLoading())

                let msg = ''

                if (e.message.includes('auth/user-not-found')) {
                    msg = 'El email no se encuentra registrado.'
                } else if (e.message.includes('auth/wrong-password')) {
                    msg = 'La contraseña ingresada es incorrecta.'
                } else {
                    msg = 'Ha ocurrido un error'
                }

                if (e.message.includes('auth/user-disabled')) {
                    history.push('/auth/status')
                } else {
                    dispatch(setError(msg))

                }
            })

    }

}

// FUNCION LOGIN QUE CREA EL OBJETO AUTH DEL STORE //

export const login = ({ uid, name, token, expirationTime }: AuthReducerProps) => (
    {
        type: types.login,
        payload: {
            uid,
            name,
            token,
            expirationTime
        }
    }
)

// CERRAR SESION //

export const startLogout = () => {

    return async (dispatch: any) => {

        const auth = getAuth();

        await signOut(auth);

        dispatch(logout());


    }
}

// FUNCION LOGOUT PARA BORRAR EL USER //

export const logout = () => ({
    type: types.logout,
})

// ENVIAR MAIL DE VERIFICACION DE CORREO //

export const startSendEmailVerification = (email: any, password: any, seller: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const auth = getAuth();

        const user = auth.currentUser;

        if (user) {

            // await sendEmailVerification(user, { url: 'http://localhost:3000/auth/login' })
            await sendEmailVerification(user, { url: 'https://software-tracker-production.netlify.app/auth/login' })
                .then(async () => {

                    dispatch(finishLoading())

                    await Swal.fire('¡Atención!', 'Le hemos enviado un mail para verificar su cuenta. Por favor revise su correo electrónico.', 'info');

                }).catch(async e => {

                    dispatch(finishLoading())

                    console.log(e);

                    let msg = ''

                    if (e.message.includes('auth/user-not-found')) {
                        msg = 'El email no se encuentra registrado.'
                    } else if (e.message.includes('auth/wrong-password')) {
                        msg = 'La contraseña ingresada es incorrecta.'
                    } else {
                        msg = 'Ha ocurrido un error'
                    }

                    dispatch(setError(msg));
                })

        } else {

            dispatch(setError('No se encuentra autenticado.'));

        }

    }

}

// ENVIAR MAIL PARA CAMBIAR CONTRASEÑA //

export const startSendPasswordResetEmail = (email: any) => {

    return async (dispatch: any) => {

        dispatch(startLoading())

        const auth = getAuth();

        // sendPasswordResetEmail(auth, email, { url: 'http://localhost:3000/auth/login' })
        await sendPasswordResetEmail(auth, email, { url: 'https://software-tracker-production.netlify.app/auth/login' })
            .then(() => {

                Swal.fire('¡Atención!', 'Le hemos enviado un mail para que modifique su contraseña. Por favor revise su correo electrónico.', 'info')

                dispatch(finishLoading())

            }).catch(e => {
                console.log(e);
                dispatch(finishLoading())

                let msg = ''

                if (e.message.includes('auth/invalid-email')) {
                    msg = 'El email no es correcto.'
                } else if (e.message.includes('auth/user-not-found')) {
                    msg = 'Usuario no encontrado.'
                } else {
                    msg = 'Ha ocurrido un error'
                }

                dispatch(setError(msg));


            })

    }

}
