
export const AppLogoComponent = () => {

    return (

        <div className='general__logo-container'>

            <img
                className='general__logo'
                src={require('../images/logo.png')}
                alt='logo'
            />

            <h1 className='general__logoName'>SoftwareTracker</h1>

        </div>

    )
}
