import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { authReducer } from '../reducers/authReducer';
import { uiReducer } from '../reducers/uiReducer';
import { srAndamiosReducer } from '../reducers/srAndamiosReducer';

const reducers = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    srandamios: srAndamiosReducer,
});

export const store = createStore(
    reducers,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
); 