import { styled } from 'styled-components';
import { OptionWorkButtons } from '../../../components/OptionWorkButtons';
import { Typography } from '../../../components/Typography';
import { device, colors } from '../../../styles/stylesConfig';
import { useEffect, useState } from 'react';
import { WorksView } from '../../../components/WorksView';
import { NewWorkModal } from '../../../components/NewWorkModal';
import { initialStateNewWork } from '../../../utils/Jsons';
import { WorkDetailModal } from '../../../components/WorkDetailModal';
import { formatExpirationTime, getWorksListSorted, getWorksListSortedByDaysLeft, getWorksListSortedByFinished } from '../../../utils/Utilities';
import { WorkViewsTypes, WorkProps, ViewButtonsProps, ActionButtonsProps } from '../../../interfaces/WorkInterfaces';
import { LoadingComponent } from '../../../components/LoadingComponent';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../actions/auth';
import { getAuth } from 'firebase/auth';

const ScreenContainer = styled.div`
position: relative;
width: 100%;

    @media ${device.lg} {

        & > h1 {
        visibility: hidden;
        position: absolute;
        }
    }

    @media ${device.sm} {
        max-width: 80%;

    }
`

export const WorksScreen = () => {

    const { works: worksList, checkingWorks } = useSelector((state: any) => state.srandamios);

    const [viewSelected, setViewSelected] = useState<WorkViewsTypes>(localStorage.getItem('viewSelected') as WorkViewsTypes ?? 'Filas');

    const [newWorkModalVisible, setNewWorkModalVisible] = useState(false);

    const [workDetailModalVisible, setWorkDetailModalVisible] = useState(false);

    const [workDetailSelected, setWorkDetailSelected] = useState<WorkProps>(initialStateNewWork);

    const dispatch = useDispatch();

    const workViewsButtonsData: ViewButtonsProps[] = [
        {
            id: 1,
            name: 'Filas',
            icon: require('../../../images/rows-dark.png'),
            iconSelected: require('../../../images/rows-primary.png'),
        },
    ]

    const worksActionButtonsData: ActionButtonsProps[] = [
        {
            id: 1,
            name: 'Agregar trabajo',
            icon: require('../../../images/plus-white.png'),
            type: 'primary',
            styles: {
                height: '30 !important',
                alignContent: 'center',
                alignSelf: 'center',
            },
            handleClick: async () => {
                setWorkDetailSelected(initialStateNewWork);
                setNewWorkModalVisible(true);

                const auth = getAuth();

                const tokenResult = await auth.currentUser!.getIdTokenResult(true)

                dispatch(login({
                    uid: auth.currentUser!.uid,
                    name: auth.currentUser!.displayName,
                    token: tokenResult.token,
                    expirationTime: formatExpirationTime(tokenResult.expirationTime)
                }))
            },
        },

    ]

    useEffect(() => {

        const storedviewSelectedData = localStorage.getItem('viewSelected');
        if (storedviewSelectedData) {
            setViewSelected(storedviewSelectedData as 'Filas' | 'Tarjetas');
        }

    }, [worksList, viewSelected])

    return (

        <ScreenContainer>

            <Typography
                size='24px'
                bold
                style={{ marginBottom: 30 }}
            >
                Trabajos
            </Typography>

            <OptionWorkButtons
                viewButtonsData={workViewsButtonsData}
                actionButtonsData={worksActionButtonsData}
                viewSelected={viewSelected}
                setViewSelected={setViewSelected}
                onClickNewWork={() => { setNewWorkModalVisible(true) }}
            />

            {
                checkingWorks ? (
                    <LoadingComponent />
                ) : (

                    <>

                        {
                            getWorksListSorted(worksList.filter((value: WorkProps) => { return value.state.name === 'A confirmar' && value.extension === null }), 'active').length > 0
                            && (
                                <WorksView
                                    title='Trabajos a confirmar'
                                    color={colors.to_confirm}
                                    worksList={getWorksListSorted(worksList.filter((value: WorkProps) => { return value.state.name === 'A confirmar' && value.extension === null }), 'active')}
                                    setWorkDetailModalVisible={setWorkDetailModalVisible}
                                    setWorkDetailSelected={setWorkDetailSelected}
                                />
                            )
                        }

                        {
                            getWorksListSorted(worksList.filter((value: WorkProps) => { return (value.state.name !== 'Terminado' && value.state.name !== 'A confirmar' && value.extension !== null) }), 'active').length > 0
                            && (
                                <WorksView
                                    title='Trabajos extendidos'
                                    color={colors.warning}
                                    worksList={getWorksListSorted(worksList.filter((value: WorkProps) => { return (value.state.name !== 'Terminado' && value.state.name !== 'A confirmar' && value.extension !== null) }), 'active')}
                                    setWorkDetailModalVisible={setWorkDetailModalVisible}
                                    setWorkDetailSelected={setWorkDetailSelected}
                                />

                            )
                        }

                        {
                            getWorksListSorted(worksList.filter((value: WorkProps) => { return (value.state.name === 'Falta cobrar' && value.extension === null) }), 'active').length > 0
                            && (
                                <WorksView
                                    title='Deudores'
                                    color={colors.danger}
                                    worksList={getWorksListSorted(worksList.filter((value: WorkProps) => { return (value.state.name === 'Falta cobrar' && value.extension === null) }), 'active')}
                                    setWorkDetailModalVisible={setWorkDetailModalVisible}
                                    setWorkDetailSelected={setWorkDetailSelected}
                                />

                            )
                        }


                        {
                            getWorksListSortedByDaysLeft(worksList.filter((value: WorkProps) => { return (value.state.name !== 'Terminado' && value.state.name !== 'A confirmar' && value.state.name !== 'Falta cobrar' && value.extension === null) }), 'active').length > 0
                            && (
                                <WorksView
                                    title='Trabajos activos'
                                    color={colors.primary_light}
                                    worksList={getWorksListSortedByDaysLeft(worksList.filter((value: WorkProps) => { return (value.state.name !== 'Terminado' && value.state.name !== 'A confirmar' && value.state.name !== 'Falta cobrar' && value.extension === null) }), 'active')}
                                    setWorkDetailModalVisible={setWorkDetailModalVisible}
                                    setWorkDetailSelected={setWorkDetailSelected}
                                />

                            )
                        }

                        {
                            getWorksListSortedByFinished(worksList.filter((value: WorkProps) => { return value.state.name === 'Terminado' }), 'active').length > 0
                            && (
                                <WorksView
                                    title='Trabajos finalizados'
                                    color={colors.ok}
                                    worksList={getWorksListSortedByFinished(worksList.filter((value: WorkProps) => { return value.state.name === 'Terminado' }), 'active')}
                                    setWorkDetailModalVisible={setWorkDetailModalVisible}
                                    setWorkDetailSelected={setWorkDetailSelected}
                                />
                            )
                        }

                    </>

                )
            }

            {
                newWorkModalVisible && (
                    <NewWorkModal
                        data={initialStateNewWork}
                        worksList={worksList}
                        setVisible={setNewWorkModalVisible}
                    />
                )
            }
            {
                workDetailModalVisible && (
                    <WorkDetailModal
                        data={workDetailSelected}
                        worksList={worksList}
                        setVisible={setWorkDetailModalVisible}
                    />
                )
            }

        </ScreenContainer>


    )
}
