import { styled } from "styled-components";
import { device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useState } from "react";
import { Input } from "./Input";
import { getFullDate, sortStock } from "../utils/Utilities";
import { showMixinToast, showModalMessage } from "../utils/Alerts";
import { initialStateNewStock } from "../utils/Jsons";
import { StockProps } from "../interfaces/StockInterfaces";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, setError, startLoading } from "../actions/ui";
import Swal from "sweetalert2";
import { endpoints } from "../helpers/FirebaseHelper";
import { login } from "../actions/auth";
import { getAuth } from "firebase/auth";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 50%;
    max-width: 95%;
    max-height: 80%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 80%;
    } 


    @media ${device.md} {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0px;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

interface Props {
    data: StockProps;
    stockList: StockProps[];
    setVisible: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        data: StockProps;
    }>>
}

export const NewStockModal = ({ data, setVisible }: Props) => {

    const { name, token } = useSelector((state: any) => state.auth);

    const [form, setForm] = useState<StockProps>(data);

    const [isEdit] = useState<boolean>(data.id !== 0);

    const dispatch = useDispatch();

    const [fieldIncomplete, setFieldIncomplete] = useState({
        product: false,
        totalAmount: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            product: form.product.trim() === '',
            totalAmount: form.totalAmount <= 0,
        });

        if (form.product.trim() === '') {
            existError = true;
        }
        if (form.totalAmount <= 0) {
            existError = true;
        }

        return existError;

    }

    const handleFormConfirm = async () => {

        if (!formValidations()) {

            let stockDocument: StockProps = initialStateNewStock

            if (!isEdit) {

                stockDocument = { ...form, id: parseInt(getFullDate()) }

                setVisible({
                    visible: false,
                    data: initialStateNewStock
                });

            } else {

                stockDocument = form

                setVisible({
                    visible: false,
                    data: initialStateNewStock
                });

            }

            dispatch(startLoading())

            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createStock}`,
                { collection: "Stock", id: stockDocument.id.toString(), doc: stockDocument },
                { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {

                    dispatch(finishLoading())

                    setVisible({
                        visible: false,
                        data: initialStateNewStock
                    });

                    showMixinToast(isEdit ? 'Producto actualizado' : 'Nuevo producto creado', 'success')

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    setVisible({
                        visible: false,
                        data: initialStateNewStock
                    });

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));


                })

            setVisible({
                visible: false,
                data: initialStateNewStock
            });

            dispatch(finishLoading())


        } else {

            showModalMessage('Atención', 'Falta completar información.', 'error');

        }

    }

    return (

        <ShadowContainer>

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {data.id === -1 ? 'Nuevo producto' : 'Editar producto'}
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => {
                            setVisible({
                                visible: false,
                                data: initialStateNewStock
                            });
                        }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <Input
                    type="text"
                    label="Producto"
                    value={form.product}
                    placeholder="Nombre del producto"
                    incomplete={fieldIncomplete.product}
                    onChange={(e) => {

                        setFieldIncomplete({ ...fieldIncomplete, product: false });

                        setForm({
                            ...form,
                            product: e.currentTarget.value
                        })
                    }}
                />

                <Input
                    type="text"
                    label="Descripción"
                    value={form.description}
                    placeholder="Medidas, detalles, etc."
                    onChange={(e) => {

                        setForm({
                            ...form,
                            description: e.currentTarget.value
                        })
                    }}
                />

                <Input
                    type="number"
                    label="Cantidad total"
                    value={form.totalAmount > 0 ? form.totalAmount : ''}
                    placeholder="Cantidad total del producto"
                    incomplete={fieldIncomplete.totalAmount}
                    onChange={(e) => {

                        setFieldIncomplete({ ...fieldIncomplete, totalAmount: false });

                        setForm({
                            ...form,
                            totalAmount: parseInt(e.currentTarget.value)
                        })
                    }}
                />

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={data.id === 0 ? 'Crear nuevo producto' : 'Actualizar producto'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={false}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible({
                            visible: false,
                            data: initialStateNewStock
                        });
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
