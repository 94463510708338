
interface Props {
    label: string
    value: any
    placeholder?: string
    description?: string
    readOnly?: boolean
    onChange: (e: any) => void
    onKeyDown?: (e: any) => void
}

export const PrimaryInputComponent = ({ label, value, description, placeholder, readOnly, onChange, onKeyDown }: Props) => {

    return (

        <div className='input__primary-container'>

            <h2 className='input__primary-label'>{label}</h2>

            <input
                className="input__primary-input"
                type={label.toLowerCase().includes('contraseña') ? "password" : "text"}
                placeholder={placeholder}
                value={value}
                onChange={(e: any) => onChange(e)}
                disabled={readOnly}
                onKeyDown={onKeyDown ?? undefined}
            />


            {description && (<h3 className='input__primary-description'>{description}</h3>)}

        </div>


    )
}


