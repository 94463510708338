import { types } from '../types/types';

const initialState = {
    works: [],
    checkingWorks: true,
    stock: [],
    checkingStock: true,
    movements: [],
    checkingMovements: true,
}

export const srAndamiosReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case types.srAndamiosSetWorks:
            return {
                ...state,
                works: action.payload.works
            }

        case types.srAndamiosRemoveWorks:
            return {
                ...state,
                works: []
            }

        case types.setCheckingSrAndamiosWorks:
            return {
                ...state,
                checkingWorks: true
            }

        case types.removeCheckingSrAndamiosWorks:
            return {
                ...state,
                checkingWorks: false
            }

        case types.srAndamiosSetStock:
            return {
                ...state,
                stock: action.payload.stock
            }

        case types.srAndamiosRemoveStock:
            return {
                ...state,
                stock: []
            }

        case types.setCheckingSrAndamiosStock:
            return {
                ...state,
                checkingStock: true
            }

        case types.removeCheckingSrAndamiosStock:
            return {
                ...state,
                checkingStock: false
            }

        case types.srAndamiosSetMovements:
            return {
                ...state,
                movements: action.payload.movements
            }

        case types.srAndamiosRemoveMovements:
            return {
                ...state,
                movements: []
            }

        case types.setCheckingSrAndamiosMovements:
            return {
                ...state,
                checkingMovements: true
            }

        case types.removeCheckingSrAndamiosMovements:
            return {
                ...state,
                checkingMovements: false
            }

        default:
            return state;

    }

}