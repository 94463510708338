interface Props {
    text: string
}

export const ParagraphComponent = ({ text }: Props) => {

    return (

        <div className='general__paragraph-container'>

            <p className='general__paragraph'>{text}</p>

        </div>


    )
}
