import validator from 'validator';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/ui';
import { LinkButtonComponent } from '../../components/LinkButtonComponent';
import { PrimaryInputComponent } from '../../components/PrimaryInputComponent';
import { useState } from 'react';
import { AuthTitleComponent } from '../../components/AuthTitleComponent';
import { PrimaryButtonComponent } from '../../components/PrimaryButtonComponent';
import { ParagraphComponent } from '../../components/ParagraphComponent';

export const SecondFactorScreen = () => {

    const dispatch = useDispatch();

    const [values, setValues] = useState<{ email: string }>({
        email: ''
    })


    const { email } = values;

    const handleResendCode = () => {

        if (isFormValid()) {

            // dispatch(startRegisterWithEmailPassword(email, password, (fullName), ''))

        }

    }

    const handleVerifySecondFactor = (e: any) => {

        e.preventDefault();

        if (isFormValid()) {

            // dispatch(startRegisterWithEmailPassword(email, password, (fullName), ''))

        }

    }

    const isFormValid = () => {

        if (!validator.isEmail(email)) {
            dispatch(setError('El email no es valido'));
            return false;
        } else {

            return true;
        }

    }

    return (

        <>

            <AuthTitleComponent
                text={`Vamos a validar que sos vos`}
            />

            <ParagraphComponent
                text={`Te vamos a enviar un SMS a +54********20 para verificar que sos vos.`}
            />

            <PrimaryInputComponent
                label='Código enviado a tu teléfono'
                value={email}
                description="El código enviado a tu teléfono podría tardar unos minutos. Si todavía no lo recibiste, tocá el botón 'Reenviar código'"
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        email: e.target.value
                    })
                    // handleOrderChange(e)
                }}
            />


            <PrimaryButtonComponent
                type='primary'
                text='Validar código'
                fullWidth
                onClick={(e: any) => handleVerifySecondFactor(e)}
            />

            <PrimaryButtonComponent
                type='grey'
                text='Reenviar código en 00:59'
                disabled
                onClick={() => handleResendCode()}
            />


            <LinkButtonComponent
                label='¿Todavía no te creaste tu cuenta?'
                text='Registrate ahora'
                link='/auth/signIn'
            />


        </>

    )
}
