import { styled } from "styled-components";
import { device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./Button";

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    & > h1 {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.lg} {
        width: 100%;
    } 

`

const HeaderTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    & > h1:first-of-type {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media ${device.lg} {
        visibility: hidden;
    } 

    @media ${device.sm} {
        position: absolute;
    } 
`

const OptionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;

    @media ${device.sm} {
        flex-direction: column;
        width: 100% !important;
        & > button {
            margin: 0px !important;
        }
        & > button:nth-child(2) {
            margin-top: 10px !important;
        }
    } 

`

interface Props {
    title: string;
    subtitle?: string;
    primaryButtonText?: string;
    primaryButtonIcon?: string;
    primaryButtonAction?: () => void;
    secondaryButtonText?: string;
    secondaryButtonIcon?: string;
    secondaryButtonAction?: () => void;
}

export const ActionScreenHeader = ({
    title, subtitle, primaryButtonText, primaryButtonIcon, primaryButtonAction, secondaryButtonText, secondaryButtonIcon, secondaryButtonAction
}: Props) => {


    return (

        <HeaderContainer>

            <HeaderTitleContainer>

                <Typography
                    size='24px'
                    bold
                >
                    {title}
                </Typography>

                {subtitle && (
                    <Typography
                        size='14px'
                        style={{ marginTop: 15 }}
                    >
                        {subtitle}
                    </Typography>
                )}

            </HeaderTitleContainer>

            <OptionButtonsContainer>

                {
                    primaryButtonAction && (
                        <Button
                            type="primary"
                            text={primaryButtonText ?? ''}
                            icon={primaryButtonIcon ?? ''}
                            style={{ height: 30, marginTop: 0 }}
                            onClick={() => { primaryButtonAction() }}
                        />
                    )
                }

                {
                    secondaryButtonAction && (
                        <Button
                            type="secondary"
                            text={secondaryButtonText ?? ''}
                            icon={secondaryButtonIcon ?? ''}
                            style={{ height: 30, marginTop: 0, marginRight: 20 }}
                            onClick={() => { secondaryButtonAction() }}
                        />
                    )
                }


            </OptionButtonsContainer>

        </HeaderContainer>

    )
}
