import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "./Icon";
import { styled } from "styled-components";
import { Typography } from "./Typography";
import { colors, device } from "../styles/stylesConfig";
import { useDispatch } from "react-redux";
import { closeMenu, closeNotifications } from "../actions/ui";
import { ProyectMenuItemProps } from "./ProyectsMenu";
import { useEffect, useState } from "react";

const ItemButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    width: 90%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: ${props => props.selected ? colors.info_light : 'transparent'};
    border: transparent 1px solid;
    visibility: visible;

    &:hover {
        cursor: pointer;
        border: 1px solid ${colors.info};
        box-shadow: 0px 0px 6px #00000026;
        
    }

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
`

const SubItemsContainer = styled.div`
    width: 90%;
    padding-left: 30px;
    
    @media ${device.lg} {
        min-width: 100%;
        display: flex;
        flex-direction: row;
        padding-left: 0px;
    }
`

const SubItemButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: ${props => props.selected ? colors.info_light : 'transparent'};
    border: transparent 1px solid;

    &:hover {
        cursor: pointer;
        border: 1px solid ${colors.info};
        box-shadow: 0px 0px 6px #00000026;        
    }

    @media ${device.lg} {
        width: repeat(1fr, auto);
        min-width: 150px;
        justify-content: center;
        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0px;
        border: 5px solid transparent;
        background-color: ${props => props.selected ? colors.info_light : 'transparent'};
        border-bottom-color: ${props => props.selected ? colors.primary_light : 'transparent'};

        &:hover {
        cursor: pointer;
        border: 5px solid transparent;
        border-bottom-color: ${props => props.selected ? colors.primary_light : 'transparent'};
        box-shadow: none;
        }

        & > h1 {
            color: ${props => props.selected ? colors.dark : colors.grey};
            font-weight: ${props => props.selected ? 500 : 400};
        }
    }
`

export const ProyectsMenuItem = ({ itemName, icon, selected, goTo, subItems }: ProyectMenuItemProps) => {

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation();

    const [pathname, setPathname] = useState('');

    useEffect(() => {

        if (location.pathname.includes('/works')) {
            setPathname('/works');
        } else if (location.pathname.includes('/calendar')) {
            setPathname('/calendar');
        } else if (location.pathname.includes('/contacts')) {
            setPathname('/contacts');
        } else if (location.pathname.includes('/stock')) {
            setPathname('/stock');
        } else if (location.pathname.includes('/budget')) {
            setPathname('/budget');
        } else if (location.pathname.includes('/settings')) {
            setPathname('/settings');
        } else {
            setPathname('');
        }

    }, [location])

    return (

        <>

            <ItemButton
                selected={selected}
                onClick={() => {
                    dispatch(closeMenu());
                    history.push(goTo);
                }}
            >

                <Icon size="S" src={require('../images/arrow-right-dark.png')} style={{ transform: selected ? 'rotate(90deg)' : 'none' }} />

                <Icon size="M" src={icon} style={{ marginLeft: 15 }} />

                <Typography
                    size="14px"
                    bold={selected}
                    style={{ marginLeft: 10, textAlign: 'start' }}
                >
                    {itemName}
                </Typography>

            </ItemButton>

            {
                (selected && subItems.length > 0) && (

                    <SubItemsContainer>

                        {
                            subItems?.map((item) => {

                                let isSelected = false;

                                if (item.itemName === 'Trabajos' && pathname === '/works') {
                                    isSelected = true;
                                } else if (item.itemName === 'Calendario' && pathname === '/calendar') {
                                    isSelected = true;
                                } else if (item.itemName === 'Contactos' && pathname === '/contacts') {
                                    isSelected = true;
                                } else if (item.itemName === 'Stock' && pathname === '/stock') {
                                    isSelected = true;
                                } else if (item.itemName === 'Presupuesto' && pathname === '/budget') {
                                    isSelected = true;
                                } else if (item.itemName === 'Ajustes' && pathname === '/settings') {
                                    isSelected = true;
                                }

                                return (

                                    <SubItemButton
                                        key={item.itemName}
                                        selected={isSelected}
                                        onClick={() => {
                                            dispatch(closeMenu());
                                            dispatch(closeNotifications());
                                            history.push(item.goTo);
                                        }}
                                    >

                                        <Icon size="M" src={item.icon} />

                                        <Typography
                                            size="14px"
                                            style={{ marginLeft: 10, textAlign: 'start' }}
                                        >
                                            {item.itemName}
                                        </Typography>

                                    </SubItemButton>

                                )
                            })
                        }

                    </SubItemsContainer>

                )
            }

        </>

    )
}
