import { Link } from "react-router-dom"

interface Props {
    label?: string
    text: string
    link: string
    params?: object
    onClick?: () => void
}

export const LinkButtonComponent = ({ label, text, link, params, onClick }: Props) => {

    return (

        <>

            <h1 className='general__labelButton'>{label ? label : ''}</h1>

            <Link
                to={{
                    pathname: link,
                    state: params
                }}
                className='btn-link'
                onClick={() => { onClick && onClick() }}
            >
                {text}
            </Link>

        </>

    )
}
