import { BudgetMovementProps } from "../interfaces/BudgetInterfaces";
import { StockProps, WorkStockProps } from "../interfaces/StockInterfaces";
import { CommentProps, PaymentProps, SetDateTimeProps, WorkProps } from "../interfaces/WorkInterfaces";
import { colors } from '../styles/stylesConfig';

export const initialStateNewDateTime: SetDateTimeProps = {
    id: 0,
    date: 0,
    hourType: "Horario específico",
    hourStart: '12:00hs',
    hourEnd: '12:30hs'
}

export const initialStateNewPayment: PaymentProps = {
    id: 0,
    payed: false,
    datePayed: null,
    responsiblePayed: null,
    price: 0,
    dateTime: initialStateNewDateTime,
    paymentInstance: 'Al momento de la entrega',
    paymentMethod: 'Efectivo',
    invoicing: "Sin factura"
}

export const initialStateNewWork: WorkProps = {
    id: 0,
    client: '',
    address: '',
    state: { id: 0, name: 'A confirmar', color: colors.to_confirm },
    daysAmount: 0,
    deliveryDate: initialStateNewDateTime,
    retirementDate: initialStateNewDateTime,
    stock: [],
    payment: [initialStateNewPayment],
    extension: null,
    creator: '',
    createdDate: 0,
    activity: [],
    comments: [],
    fieldLocationPath: 'active',
    projectId: 1
}

export const initialStateNewStock: StockProps = {
    id: 0,
    product: '',
    description: '',
    totalAmount: 0,
}

export const initialStateNewWorkStock: WorkStockProps = {
    id: 0,
    amount: 0,
    toDeliverRetire: null,
}

export const initialStateNewComment: CommentProps = {
    id: 0,
    creator: '',
    date: 0,
    text: ''
}

export const initialStateNewBudget: BudgetMovementProps = {
    id: 0,
    type: 'expense',
    subject: '',
    responsible: '',
    paymentMethod: 'Efectivo',
    amount: 0,
    date: 0,
    workId: null
}

export const jsonWorksList: WorkProps[] = [
    {
        id: 1,
        client: 'Barrio Polvorines',
        address: 'Pte. Arturo Illia 5884, Los Polvorines',
        state: { id: 1, name: 'Para entregar', color: `${colors.primary}` },
        daysAmount: 30,
        deliveryDate: {
            id: 1,
            date: 20231130,
            hourType: "Horario específico",
            hourStart: '10:00hs',
            hourEnd: '10:30hs'
        },
        retirementDate: {
            id: 1,
            date: 20231202,
            hourType: "Horario específico",
            hourStart: '15:00hs',
            hourEnd: '16:30hs'
        },
        stock: [{
            id: 1,
            amount: 5,
            toDeliverRetire: null,
        }],
        extension: {
            date: {
                id: 1,
                date: 20231207,
                hourType: "Horario específico",
                hourStart: '15:00hs',
                hourEnd: '16:30hs'
            },
            stock: [{
                id: 1,
                amount: 5,
                toDeliverRetire: null,
            }]
        },
        payment: [{
            id: 1,
            payed: false,
            datePayed: null,
            responsiblePayed: null,
            price: 1000,
            dateTime: {
                id: 1,
                date: 20231130,
                hourType: "Horario específico",
                hourStart: '12:00hs',
                hourEnd: '12:30hs'
            },
            paymentInstance: 'Al momento de la entrega',
            paymentMethod: 'Efectivo',
            invoicing: "Sin factura"
        }],
        creator: 'Franco Grillo',
        createdDate: 20230905,
        activity: [],
        comments: [],
        fieldLocationPath: 'active',
        projectId: 1
    },
    {
        id: 2,
        client: 'Prueba',
        address: 'Pte. Arturo Illia 5884, Los Polvorines',
        state: { id: 1, name: 'Para entregar', color: `${colors.primary}` },
        daysAmount: 30,
        deliveryDate: {
            id: 1,
            date: 20231130,
            hourType: "Horario específico",
            hourStart: '10:00hs',
            hourEnd: '10:30hs'
        },
        retirementDate: {
            id: 1,
            date: 20231202,
            hourType: "Horario específico",
            hourStart: '15:00hs',
            hourEnd: '16:30hs'
        },
        stock: [{
            id: 1,
            amount: 5,
            toDeliverRetire: null,
        }],
        extension: null,
        payment: [{
            id: 1,
            payed: true,
            datePayed: 20231130121015,
            responsiblePayed: 'Franco Grillo',
            price: 1000,
            dateTime: {
                id: 1,
                date: 20231130,
                hourType: "Horario específico",
                hourStart: '12:00hs',
                hourEnd: '12:30hs'
            },
            paymentInstance: 'Al momento de la entrega',
            paymentMethod: 'Efectivo',
            invoicing: "Sin factura"
        }],
        creator: 'Franco Grillo',
        createdDate: 20230905,
        activity: [],
        comments: [],
        fieldLocationPath: 'active',
        projectId: 1
    }

]

export const hoursJson = [
    '00:00hs',
    '00:30hs',
    '01:00hs',
    '01:30hs',
    '02:00hs',
    '02:30hs',
    '03:00hs',
    '03:30hs',
    '04:00hs',
    '04:30hs',
    '05:00hs',
    '05:30hs',
    '06:00hs',
    '06:30hs',
    '07:00hs',
    '07:30hs',
    '08:00hs',
    '08:30hs',
    '09:00hs',
    '09:30hs',
    '10:00hs',
    '10:30hs',
    '11:00hs',
    '11:30hs',
    '12:00hs',
    '12:30hs',
    '13:00hs',
    '13:30hs',
    '14:00hs',
    '14:30hs',
    '15:00hs',
    '15:30hs',
    '16:00hs',
    '16:30hs',
    '17:00hs',
    '17:30hs',
    '18:00hs',
    '18:30hs',
    '19:00hs',
    '19:30hs',
    '20:00hs',
    '20:30hs',
    '21:00hs',
    '21:30hs',
    '22:00hs',
    '22:30hs',
    '23:00hs',
    '23:30hs',
]