import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Icon } from "./Icon";
import { useEffect, useState } from "react";
import { Typography } from "./Typography";
import { Menu } from "./Menu";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeMenu, closeNotifications, openMenu, openNotifications } from "../actions/ui";

const SideBar = styled.div`
    width: 100%;
    height: 70px;
    box-shadow: none; 
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
    position: relative;
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: ${colors.info_light};

    & > h1 {
        visibility: hidden;
        position: absolute;

        @media ${device.lg} {
            visibility: visible;
            position: unset;
            padding-left: 10px;
            padding-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        } 

        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.lg} {
        background-color: white;
        border-bottom: 1px solid ${colors.grey_normal};
    } 


    @media ${device.md} {
        padding-left: 30px;
        padding-right: 30px;
    } 

    @media ${device.sm} {
        padding-left: 20px;
        padding-right: 20px;
    } 

`

const LogoComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    } 


`

const NavContainer = styled.div`
    display: flex;
    position: relative;
`

const NavButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    font-size: 14px;
    border: transparent 1px solid;
    background-color: transparent;
    margin-left: 30px;
    

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
        margin-left: 0px;
    } 

    &:hover {
        cursor: pointer;
        border: ${colors.info} 1px solid;
        box-shadow: 0px 0px 6px #00000026;

        @media ${device.lg} {
            border: 1px solid  transparent;
            box-shadow: none;
        } 
    }

    & > hr {
        width: 15px;
        border: none;

        @media ${device.sm} {
            width: 0px;
            visibility: hidden;
        } 
    }

    @media ${device.sm} {
       font-size: 0px;
    } 
`

const MenuButton = styled(NavButton)`
    position: absolute;
    visibility: hidden;

    @media ${device.lg} {
        position: unset;
        visibility: visible;
    } 
`

const NotifPendingNavButton = styled(NavButton)`
display: flex;
justify-content: center;
align-items: center;
padding: 10px 20px;
font-size: 14px;
border: transparent 1px solid;
background-color: ${colors.danger_light};
position: relative;

&:hover {
    cursor: pointer;
    border: ${colors.info} 1px solid;
    box-shadow: 0px 0px 6px #00000026;
}

& > hr {
    width: 15px;
    border: none;
}
`

const NotificationsPendingAmount = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: white;
    background-color: ${colors.danger};
    border-radius: 50px;
    margin-right: 15px;
`
export const ProyectHeaderBar = () => {

    const [pendingNotification] = useState(false);

    const dispatch = useDispatch();

    const history = useHistory();

    const location = useLocation()

    const [titleState, setTitleState] = useState('');

    useEffect(() => {

        const setTitle = (pathname: string) => {

            if (pathname.includes('/proyects/2')) {
                setTitleState('SR Andamios');
            } else {
                setTitleState('');
            }
        }

        setTitle(location.pathname);

    }, [location])

    return (

        <SideBar>

            <LogoComponent>

                <Icon
                    size="XL"
                    src={require('../images/logo.png')}
                />

                <Typography
                    size="16px"
                    style={{ marginLeft: 10 }}
                >
                    SoftwareTracker
                </Typography>

            </LogoComponent>

            <MenuButton
                onClick={() => {
                    dispatch(closeNotifications());
                    dispatch(openMenu());
                }}
            >
                <>
                    <Icon size="M" src={require('../images/menu.png')} />
                    <hr />
                    Menú
                </>
            </MenuButton>

            <Typography
                size="28px"
                bold
            >
                {titleState}
            </Typography>

            <Menu type="tablet/mobile" selected="" />

            {/* <Notifications /> */}

            <NavContainer>

                <NavButton
                    onClick={() => {
                        dispatch(closeMenu());
                        dispatch(closeNotifications());
                        history.push('/home')
                    }}
                >
                    <>
                        <Icon size="M" src={require('../images/home.png')} />
                        <hr />
                        Inicio
                    </>
                </NavButton>

                <NavButton
                    onClick={() => {
                        dispatch(closeMenu());
                        dispatch(closeNotifications());
                        history.push('/profile')
                    }}
                >
                    <>
                        <Icon size="M" src={require('../images/user.png')} />
                        <hr />
                        Perfil
                    </>
                </NavButton>

                <NavButton
                    onClick={() => {
                        dispatch(closeMenu());
                        dispatch(closeNotifications());
                        history.push('/proyects')
                    }}
                >
                    <>
                        <Icon size="M" src={require('../images/proyects.png')} />
                        <hr />
                        Proyectos
                    </>
                </NavButton>

                {/* {!pendingNotification ?
                    (

                        <NavButton
                            onClick={() => {
                                dispatch(closeMenu());
                                dispatch(openNotifications());
                            }}
                        >
                            <>
                                <Icon size="M" src={require('../images/notification.png')} />
                                <hr />
                                Notificaciones
                            </>
                        </NavButton>

                    ) :
                    (

                        <NotifPendingNavButton
                            onClick={() => {
                                dispatch(closeMenu());
                                dispatch(openNotifications());
                            }}
                        >
                            <>
                                <NotificationsPendingAmount >5</NotificationsPendingAmount>
                                <Icon size="M" src={require('../images/notification.png')} />
                                <hr />
                                Notificaciones pendientes
                            </>
                        </NotifPendingNavButton>

                    )
                } */}


            </NavContainer>

        </SideBar>

    )
}
