import { styled } from 'styled-components';
import { colors, device } from '../../../styles/stylesConfig';
import { useEffect, useState } from 'react';
import { initialStateNewBudget } from '../../../utils/Jsons';
import { ActionScreenHeader } from '../../../components/ActionScreenHeader';
import { BudgetMovementProps, BudgetProps } from '../../../interfaces/BudgetInterfaces';
import { BudgetListView } from '../../../components/BudgetListView';
import { Typography } from '../../../components/Typography';
import { formatPrice, getFullDate, getWorksListSorted } from '../../../utils/Utilities';
import { NewBudgetModal } from '../../../components/NewBudgetModal';
import { LoadingComponent } from '../../../components/LoadingComponent';
import { useSelector } from 'react-redux';
import { WorkProps } from '../../../interfaces/WorkInterfaces';

const ScreenContainer = styled.div`
position: relative;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;

    @media ${device.lg} {

        & > h1 {
        visibility: hidden;
        position: absolute;
        }
    }

    @media ${device.sm} {
        max-width: 80%;

    }
`

const BudgetNumberContainer = styled.div`
width: fit-content;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius: 4px;
border: 1px solid ${colors.info};
background-color: ${colors.info_light};
margin-top: 50px;
padding: 20px 100px;

    & > h1 {
        text-transform: uppercase;

        &:nth-child(2) {
        font-weight: bold;
        margin-bottom: 7px;
        }
    }

    @media ${device.sm} {
        padding: 20px 50px;
    }

    @media ${device.xs} {
        padding: 20px 30px;

        & > h1 {
            font-size: 14px;

            &:nth-child(2) {
                font-size: 20px;
            }
        }
    }

`

export const BudgetScreen = () => {

    const { works: worksList, checkingWorks, movements: budgetList, checkingMovements } = useSelector((state: any) => state.srandamios);

    const [newBudgetModalVisible, setNewBudgetModalVisible] = useState(false);

    const [workDetailSelected] = useState<BudgetMovementProps>(initialStateNewBudget);

    const getWorkMovementsList = () => {

        let list: BudgetMovementProps[] = []

        getWorksListSorted(worksList, 'active').forEach((work: WorkProps) => {

            work.payment.forEach((pay) => {

                if (pay.payed) {

                    list = [
                        ...list,
                        {
                            id: pay.id,
                            type: 'income',
                            subject: work.client,
                            amount: pay.price,
                            responsible: pay.responsiblePayed ?? '',
                            paymentMethod: pay.paymentMethod,
                            date: pay.datePayed ?? parseInt(getFullDate()),
                            workId: work.id,
                        }
                    ]

                }

            })

        })

        return list

    }

    const setAmountNumber = () => {

        const list = [...budgetList, ...getWorkMovementsList()]

        let cashAmount = 0
        let mercadoPagoAmount = 0
        let bankTransferAmount = 0
        let usdAmount = 0

        list.forEach((value: BudgetMovementProps) => {

            switch (value.paymentMethod) {
                case 'Efectivo':
                    cashAmount += value.amount;
                    break;

                case 'Mercado Pago':
                    mercadoPagoAmount += value.amount;
                    break;

                case 'Transferencia bancaria':
                    bankTransferAmount += value.amount;
                    break;

                case 'Dólares':
                    usdAmount += value.amount;
                    break;
            }

        })

        return { cashAmount, mercadoPagoAmount, bankTransferAmount, usdAmount }
    }
    return (

        <ScreenContainer>

            {
                newBudgetModalVisible && (
                    <NewBudgetModal
                        data={workDetailSelected}
                        setVisible={setNewBudgetModalVisible}
                    />
                )
            }

            <ActionScreenHeader
                title='Presupuesto'
                primaryButtonText='Agregar movimiento'
                primaryButtonIcon={require('../../../images/plus-white.png')}
                primaryButtonAction={() => { setNewBudgetModalVisible(true) }}
            />

            <BudgetNumberContainer>

                {
                    checkingMovements && checkingWorks ? (

                        <LoadingComponent />

                    ) : (

                        <>

                            <Typography size='18px'>Presupuesto total en pesos</Typography>

                            <Typography size='24px'>${formatPrice(setAmountNumber().cashAmount + setAmountNumber().mercadoPagoAmount + setAmountNumber().bankTransferAmount)}</Typography>

                            <Typography size='16px' bold style={{ marginTop: 15, width: '100%', textAlign: 'start', textTransform: 'capitalize' }}><span>Efectivo: </span>${formatPrice(setAmountNumber().cashAmount)}</Typography>

                            <Typography size='16px' bold style={{ marginTop: 5, width: '100%', textAlign: 'start', textTransform: 'capitalize' }}><span>Mercado Pago: </span>${formatPrice(setAmountNumber().mercadoPagoAmount)}</Typography>

                            <Typography size='16px' bold style={{ marginTop: 5, width: '100%', textAlign: 'start', textTransform: 'capitalize' }}><span>Cuenta bancaria: </span>${formatPrice(setAmountNumber().bankTransferAmount)}</Typography>

                            <Typography size='16px' bold style={{ marginTop: 5, width: '100%', textAlign: 'start', textTransform: 'capitalize' }}><span>Dólares: </span>U$D{formatPrice(setAmountNumber().usdAmount)}</Typography>


                        </>

                    )
                }


            </BudgetNumberContainer>

            <BudgetListView
                budgetList={[...budgetList, ...getWorkMovementsList()]}
            />

        </ScreenContainer>


    )
}
