import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Icon } from "./Icon";
import { useEffect, useState } from "react";
import { Typography } from "./Typography";
import { Notifications } from "./Notifications";
import { Menu } from "./Menu";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeMenu, closeNotifications, openMenu, openNotifications } from "../actions/ui";

const SideBar = styled.div`
    width: 84.9%;
    height: 70px;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
    position: relative;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;

    & > h1 {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.md} {
        font-size: 20px !important;
        } 

        @media ${device.sm} {
            font-size: 16px !important;
        } 
    }

    @media ${device.lg} {
        width: 100%;
    } 


    @media ${device.md} {
        padding-left: 30px;
        padding-right: 30px;
    } 

    @media ${device.sm} {
        padding-left: 20px;
        padding-right: 20px;
    } 

`

const NavContainer = styled.div`
    display: flex;
    margin-right: 100px;
    position: relative;

    @media ${device.lg} {
        margin-right: 0px;
    } 
`

const NavButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    font-size: 14px;
    border: transparent 1px solid;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        border: ${colors.info} 1px solid;
        box-shadow: 0px 0px 6px #00000026;

        @media ${device.lg} {
            border: 1px solid  transparent;
            box-shadow: none;
        } 
    }

    & > hr {
        width: 15px;
        border: none;

        @media ${device.sm} {
            width: 0px;
            visibility: hidden;
            position: absolute;
        } 
    }



    @media ${device.sm} {
       font-size: 0px;
    } 
`

const MenuButton = styled(NavButton)`
    position: absolute;
    visibility: hidden;

    @media ${device.lg} {
        position: unset;
        visibility: visible;
    } 
`

const NotifPendingNavButton = styled(NavButton)`
display: flex;
justify-content: center;
align-items: center;
padding: 10px 20px;
font-size: 14px;
border: transparent 1px solid;
background-color: ${colors.danger_light};
position: relative;

&:hover {
    cursor: pointer;
    border: ${colors.info} 1px solid;
    box-shadow: 0px 0px 6px #00000026;
}

& > hr {
    width: 15px;
    border: none;
}
`

const NotificationsPendingAmount = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: white;
    background-color: ${colors.danger};
    border-radius: 50px;
    margin-right: 15px;
`
export const HeaderBar = () => {

    const [pendingNotification] = useState(false);

    const dispatch = useDispatch();

    const location = useLocation();

    const [titleState, setTitleState] = useState('');

    useEffect(() => {

        const setTitle = (pathname: string) => {

            if (pathname === '/home') {
                setTitleState('Inicio');
            } else if (pathname.includes('/proyects')) {
                setTitleState('Proyectos');
            } else if (pathname.includes('/profile')) {
                setTitleState('Perfil');
            } else {
                setTitleState('');
            }

        }

        setTitle(location.pathname);


    }, [location])

    return (

        <SideBar>

            <MenuButton
                onClick={() => {
                    dispatch(closeNotifications());
                    dispatch(openMenu());
                }}
            >
                <>
                    <Icon size="M" src={require('../images/menu.png')} />
                    <hr />
                    Menú
                </>
            </MenuButton>

            <Typography
                size="28px"
                bold
            >
                {titleState}
            </Typography>

            <Menu type="tablet/mobile" selected="Inicio" />

            {/* <Notifications /> */}

            <NavContainer>

                <NavButton
                    onClick={() => {
                        // dispatch(closeMenu());
                        // dispatch(openNotifications());
                    }}
                    style={{ visibility: 'hidden' }}
                >
                    <>
                        <Icon size="M" src={require('../images/notification.png')} />
                        <hr />
                        Notificaciones
                    </>
                </NavButton>
                {/* 
                {!pendingNotification ?
                    (

                        <NavButton
                            onClick={() => {
                                dispatch(closeMenu());
                                dispatch(openNotifications());
                            }}
                        >
                            <>
                                <Icon size="M" src={require('../images/notification.png')} />
                                <hr />
                                Notificaciones
                            </>
                        </NavButton>

                    ) :
                    (

                        <NotifPendingNavButton
                            onClick={() => {
                                dispatch(closeMenu());
                                dispatch(openNotifications());
                            }}
                        >
                            <>
                                <NotificationsPendingAmount >5</NotificationsPendingAmount>
                                <Icon size="M" src={require('../images/notification.png')} />
                                <hr />
                                Notificaciones pendientes
                            </>
                        </NotifPendingNavButton>

                    )
                } */}


            </NavContainer>

        </SideBar>

    )
}
