import { AuthReducerProps } from "../interfaces/ReducerInterfaces";
import { types } from "../types/types";

const initialState: AuthReducerProps = {
    uid: null,
    name: null,
    token: null,
    expirationTime: null
}

export const authReducer = (state = initialState, action: any) => {

    switch (action.type) {
        case types.login:
            return {
                uid: action.payload.uid,
                name: action.payload.name,
                token: action.payload.token,
                expirationTime: action.payload.expirationTime,
            }

        case types.logout:
            return {}

        default:
            return state;
    }

}