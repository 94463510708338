import { styled } from "styled-components";
import { CommentProps, WorkProps } from "../interfaces/WorkInterfaces";
import { Typography } from "./Typography";
import { colors, device } from "../styles/stylesConfig";
import { formatFullDateText } from "../utils/Utilities";
import { Button } from "./Button";
import { showMixinToast, showModalConfirm } from "../utils/Alerts";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, setError, startLoading } from "../actions/ui";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 50px;
    border-bottom: 1px solid ${colors.grey_normal};
    padding: 0px 10px 25px 10px;

    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0px;
    }

    @media ${device.xs} {
        padding-top: 15px;
    }
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;

    @media ${device.sm} {
        text-align: start;
    }
`

const DateContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 0px;
    top: -25px;

    & > button {
        margin-left: 10px; 
        font-weight: 500;
        font-size: 12px;
        margin-top: 1px;

        @media ${device.xs} {
            margin-left: 0px; 
            margin-top: 0px;
            padding-left: 0px;
            margin-bottom: 5px;
        }
            
    }
    
    @media ${device.xs} {
        flex-direction: column-reverse;
        align-items: start;
        top: -45px;
    }
`

interface Props {
    data: CommentProps;
    workDetail: WorkProps;
    setWorkDetail: React.Dispatch<React.SetStateAction<WorkProps>>;
}

export const CommentItem = ({ data, workDetail, setWorkDetail }: Props) => {

    const { id, creator, date, text } = data;

    const { name, token } = useSelector((state: any) => state.auth);

    const { stock: stockList, checkingStock, checkingWorks } = useSelector((state: any) => state.srandamios);

    console.log(workDetail);


    const dispatch = useDispatch();

    return (

        <ItemContainer key={id}>

            <InfoContainer>

                <Typography size="14px" bold style={{ position: 'relative' }}>
                    {creator}:

                    <DateContainer>

                        <Typography size="12px" color={colors.grey}>
                            {formatFullDateText(date)}
                        </Typography>

                        <Button
                            type="terciary"
                            text="Eliminar"
                            onClick={() => {

                                const handleDelete = async () => {

                                    let commentsFiltered = workDetail.comments.filter((value) => { return value.id !== id });

                                    dispatch(startLoading())

                                    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
                                        { collection: "Works", id: workDetail.id.toString(), doc: { ...workDetail, comments: commentsFiltered } },
                                        { headers: { Authorization: `Bearer ${token}` } })
                                        .then(() => {

                                            dispatch(finishLoading())

                                            setWorkDetail({ ...workDetail, comments: commentsFiltered });

                                            showMixinToast('Comentario eliminado', 'success')

                                        }).catch(async (e) => {

                                            dispatch(finishLoading())

                                            let msg = ''

                                            if (e.response !== undefined) {

                                                if (e.response.data.trace.includes('auth/id-token-expired')) {

                                                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                                } else {
                                                    msg = e.response.data.message
                                                }

                                            } else {
                                                msg = 'Ha ocurrido un error.'
                                            }

                                            console.error({ msg, error: e });

                                            await axios.post(
                                                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                                from: 'ERROR SR ANDAMIOS',
                                                to: process.env.REACT_APP_SEND_EMAIL_TO,
                                                name: 'Yo',
                                                phone: '',
                                                text: { name, msg, error: JSON.stringify(e, null, 2) }
                                            })

                                            dispatch(setError(msg));


                                        })

                                    dispatch(finishLoading())

                                }

                                showModalConfirm({
                                    title: '¿Estas seguro?',
                                    description: 'No vas a poder revertir esta acción.',
                                    icon: 'warning',
                                    confirmButtonText: 'Si, estoy seguro',
                                    handleConfirm: () => { handleDelete() }
                                })

                            }}
                        />

                    </DateContainer>

                </Typography>

                <Typography size="14px" style={{ marginTop: 10, whiteSpace: 'normal' }}>
                    {text}
                </Typography>

            </InfoContainer>

        </ItemContainer >

    )
}