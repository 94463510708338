import { styled } from "styled-components";
import { colors } from "../styles/stylesConfig";
import { CSSProperties } from "react";
import { Typography } from "./Typography";
import { Icon } from "./Icon";
import { useHistory } from "react-router-dom";

interface Props {
    icon: string;
    text: string;
    goTo: string;
    style?: CSSProperties;
}

const ButtonCard = styled.button`
background-color: white;
border-radius: 8px;
width: 150px;
height: 150px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-bottom: 20px;
margin: 20px;
position: relative;
border: ${colors.info} 1px solid;
box-shadow: 0px 0px 6px #00000025;

&:hover {
    cursor: pointer;
    border: ${colors.primary_light} 1px solid;
    box-shadow: 0px 3px 6px #00000025;
    transform: scale(1.01);
}
`

export const ButtonCards = ({ text, goTo, icon, style }: Props) => {

    const history = useHistory();

    return (

        <ButtonCard style={style} onClick={() => { history.push(goTo) }}>

            <Icon
                size="XL"
                src={icon}
            />

            <Typography
                size='14px'
                style={{
                    marginTop: '20px',
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: 10
                }}
            >
                {text}
            </Typography>

        </ButtonCard>

    )
}
