import { types } from "../types/types";


export const setError = (err: any) => ({
    type: types.uiSetError,
    payload: err
});

export const removeError = () => ({
    type: types.uiRemoveError,
});

export const startLoading = () => ({
    type: types.uiStartLoading,
});

export const finishLoading = () => ({
    type: types.uiFinishLoading,
});

export const openMenu = () => ({
    type: types.uiOpenMenu,
});

export const closeMenu = () => ({
    type: types.uiCloseMenu,
});

export const openNotifications = () => ({
    type: types.uiOpenNotifications,
});

export const closeNotifications = () => ({
    type: types.uiCloseNotifications,
});
