
export const LoadingComponent = () => {

    return (

        <div className="sk-chase-container-component">
            <div className="sk-chase-component">
                <div className="sk-chase-dot-component"></div>
                <div className="sk-chase-dot-component"></div>
                <div className="sk-chase-dot-component"></div>
                <div className="sk-chase-dot-component"></div>
                <div className="sk-chase-dot-component"></div>
                <div className="sk-chase-dot-component"></div>
            </div>
        </div>

    )
}
