import validator from 'validator';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/ui';
import { LinkButtonComponent } from '../../components/LinkButtonComponent';
import { PrimaryInputComponent } from '../../components/PrimaryInputComponent';
import { useState } from 'react';
import { AuthTitleComponent } from '../../components/AuthTitleComponent';
import { PrimaryButtonComponent } from '../../components/PrimaryButtonComponent';
import { ParagraphComponent } from '../../components/ParagraphComponent';

export const NewPasswordScreen = () => {

    const dispatch = useDispatch();

    const [values, setValues] = useState<{ newPassword: string, confirmNewPassword: string }>({
        newPassword: '',
        confirmNewPassword: '',
    })


    const { newPassword, confirmNewPassword } = values;

    const handleNewPassword = (e: any) => {

        e.preventDefault();

        if (isFormValid()) {

            // dispatch(startRegisterWithEmailPassword(email, password, (fullName), ''))

        }

    }

    const isFormValid = () => {

        if (!validator.isEmail(newPassword)) {
            dispatch(setError('El email no es valido'));
            return false;
        } else if (newPassword !== confirmNewPassword) {
            dispatch(setError('Las contraseñas no coinciden'));
            return false;
        } else {

            return true;
        }

    }

    return (

        <>

            <AuthTitleComponent
                text={`Generá una nueva contraseña y seguí gestionando tus tareas`}
            />

            <ParagraphComponent
                text='La contraseña tiene que tener un mínimo de 8 caracteres incluyendo al menos un número.'
            />

            <PrimaryInputComponent
                label='Tu nueva contraseña'
                value={newPassword}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        newPassword: e.target.value
                    })
                }}
            />

            <PrimaryInputComponent
                label='Confirma tu nueva contraseña'
                value={confirmNewPassword}
                onChange={(e: any) => {
                    setValues({
                        ...values,
                        confirmNewPassword: e.target.value
                    })
                }}
            />

            <PrimaryButtonComponent
                type='primary'
                text='Generar nueva contraseña'
                fullWidth
                onClick={(e: any) => handleNewPassword(e)}
            />

            <LinkButtonComponent
                label='¿Querés ingresar con tu cuenta?'
                text='Accedé ahora'
                link='/auth/signIn'
            />


        </>

    )
}
